// Taken from polymer's gestures.js
const findOriginalTarget = (e: Event): EventTarget | null => {
	// shadowdom
	if (e.composedPath) {
		const targets = /** @type {!Array<!EventTarget>} */ e.composedPath();
		// It shouldn't be, but sometimes targets is empty (window on Safari).
		return targets.length > 0 ? targets[0] : e.target;
	}
	// shadydom
	return e.target;
};

export default findOriginalTarget;
