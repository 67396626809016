import { css } from 'lit';

// language=CSS
const StylesMenu = css`
	.menu-wrap {
		position: absolute;
		top: 100%;
		top: calc(100% - 0.125rem);
		z-index: 1;
		left: 0;
	}

	.menu-wrap.menu--center {
		left: 50%;
	}

	.menu-wrap.menu--right {
		left: auto;
		right: 0;
	}

	.menu {
		position: relative;
		background: #fff;
		border: var(--border-width) solid rgb(var(--silver));
		border-radius: 0.25rem;
		box-shadow: var(--shadow-lg);

		/* Override Google maps styles for context menu */
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen-Sans, Ubuntu, Cantarell,
			'Helvetica Neue', Roboto, sans-serif;
		font-size: var(--smaller);
		font-weight: 400;
		line-height: 1.5;
	}

	.menu--center .menu {
		left: -50%;
	}

	.menu_caret {
		margin-top: calc(0.5rem + var(--border-width));
	}

	.menu_caret:before,
	.menu_caret:after {
		display: inline-block;
		content: '';
		position: absolute;
		left: 1rem;
		border: solid transparent;
	}

	.menu_caret:after {
		top: calc(-1rem + var(--border-width));
		border-width: 0.5rem;
		border-bottom-color: #fff;
	}

	.menu_caret:before {
		top: calc(-1rem - var(--border-width) * 2);
		margin-left: calc(var(--border-width) * -1);
		border-width: calc(0.5rem + var(--border-width));
		border-bottom-color: rgba(var(--silver));
	}

	.menu--center .menu_caret:before,
	.menu--center .menu_caret:after {
		left: calc(50% - 0.5rem);
	}

	.menu--right .menu_caret:before,
	.menu--right .menu_caret:after {
		left: auto;
		right: 1rem;
	}

	.menu--right .menu_caret:before {
		margin-left: 0;
		margin-right: calc(var(--border-width) * -1);
	}

	.menu_header {
		padding: 0.375rem 1rem 0.375rem 1rem;
		margin: 0;
		font-size: var(--smaller);
		line-height: 1.5;
		color: rgb(var(--main-gray));
		border-bottom: 1px dotted rgba(var(--silver));
	}

	.menu_item {
		display: flex;
		align-items: center;
		padding: 0.375rem 1rem;
		color: rgb(var(--main-gray));
		fill: rgb(var(--main-gray));
		outline-offset: 0;
		text-decoration: none;
		white-space: nowrap;
	}

	.menu_item:first-of-type {
		padding-top: 0.75rem;
	}

	.menu_item:last-of-type {
		padding-bottom: 0.75rem;
	}

	.menu_item:hover {
		color: rgb(var(--light-purple));
		fill: rgb(var(--light-purple));
	}

	.menu_item:active {
		color: rgb(var(--light-purple));
		fill: rgb(var(--light-purple));
		background: rgba(var(--light-purple), 0.075);
	}

	.menu_item--selected {
		color: rgb(var(--dark-gray));
		fill: rgb(var(--dark-gray));
		background: rgba(var(--silver), 0.25);
	}

	.menu_icon {
		height: 1rem;
		width: 1rem;
		padding-right: 0.125rem;
	}

	.menu-item-wrapper {
		position: relative;
	}

	.menu-item-wrapper .menu_item {
		padding: 0.375rem 1rem;
	}

	.menu-item-wrapper:nth-child(2) .menu_item {
		padding-top: 0.75rem;
	}

	.menu-item-wrapper:last-child .menu_item {
		padding-bottom: 0.75rem;
	}
`;
export default StylesMenu;
