import { isObject } from 'lodash-es';
import { MatchFunction, Path } from 'path-to-regexp';
import { Action } from 'redux';
import UniversalRouter, {
	ErrorHandler,
	ResolveContext,
	Route,
	RouteContext,
	RouteError,
	RouteParams,
	RouteResult,
} from 'universal-router';
import { AppPages, RequestStatus } from '../constants';
import { enabledModulesConfig } from '../../config';

import { RootState, ThunkActionRoot, ThunkDispatchRoot } from '../store';
import {
	SET_ACTIVE_COLUMNS,
	SET_MUTATE_CONTENT_STATUS,
	fetchMessages,
	fetchCampaigns,
	fetchHelpPages,
	fetchMessageById,
	fetchCampaignById,
	fetchHelpPageById,
	fetchFloodgates,
	fetchFloodgateById,
} from './content';
import { navigateModal } from './routing-modal';
import { isLoggedIn } from '../reducers/user';
import { ModalView } from '../reducers/modal';
import { ActiveColumns } from '../reducers/content';
import { fetchDashboardById, fetchDashboards, SET_MUTATE_DASHBOARD_STATUS } from './dashboard';
import { fetchPlows } from './plow';

export const SET_PAGE = 'SET_LIST_PAGE';

interface SetPage extends Action<typeof SET_PAGE> {
	page?: AppPages;
}

export type NavigateActions = SetPage;

export enum SearchParamKeys {
	ACTIVE_COLUMNS = 'active-columns',
}

export interface SearchParamsParsed {
	[SearchParamKeys.ACTIVE_COLUMNS]?: string[];
}

export interface AppRouteContext {
	dispatch: ThunkDispatchRoot;
	getState: () => RootState;
	searchParams: SearchParamsParsed;
}

export interface SafeRoute<R, C, T> {
	path?: Path;
	name?: string;
	parent?: Route<R, C> | null;
	children?: Array<SafeRoute<R, C, T>> | null;
	action?: (context: RouteContext<R, C> & C, params: T) => RouteResult<R>;
	match?: MatchFunction<RouteParams>;
}

export type RouteActionReturn = boolean | null | { redirect: string };

type RawCommon = { slug: string; id: string };

export function parseSearchParams(searchString: string): SearchParamsParsed {
	return searchString
		.slice(1)
		.split('&')
		.reduce((acc, item) => {
			const [key, value] = decodeURIComponent(item).split('=');

			if (key === SearchParamKeys.ACTIVE_COLUMNS && value) {
				acc[SearchParamKeys.ACTIVE_COLUMNS] = value
					.split(',')
					.map((i: string): string => decodeURIComponent(i));
			}

			return acc;
		}, {} as SearchParamsParsed);
}

export function assembleSearchParams(params: SearchParamsParsed): string {
	const paramsEncoded = [];

	const activeColumns = params[SearchParamKeys.ACTIVE_COLUMNS];

	if (activeColumns) {
		paramsEncoded.push(
			`${SearchParamKeys.ACTIVE_COLUMNS}=${activeColumns
				.map((slug) => encodeURIComponent(slug))
				.join(',')}`,
		);
	}

	return paramsEncoded.length ? `?${paramsEncoded.join('&')}` : '';
}

export function updateURL(pathname: string, search: string, hash: string): void {
	window.history.replaceState({}, '', `${pathname}${search}${hash}`);
}

function convertActiveColumnParamsIntoRecord(paramKeys?: string[]): ActiveColumns {
	if (!paramKeys || paramKeys.length === 0) {
		return {
			id: true,
			title: true,
			status: true,
			startTime: true,
			endTime: true,
			createdBy: true,
			createdTime: true,
			lastModifiedBy: true,
			lastModifiedTime: true,
			content: true,
			url: true,
		};
	}

	return {
		id: paramKeys.includes('id'),
		title: paramKeys.includes('title'),
		status: paramKeys.includes('status'),
		startTime: paramKeys.includes('startTime'),
		endTime: paramKeys.includes('endTime'),
		createdTime: paramKeys.includes('createdTime'),
		createdBy: paramKeys.includes('createdBy'),
		content: paramKeys.includes('content'),
		url: paramKeys.includes('url'),
	};
}

export const updateURLWithNewParams =
	(paramsToUpdate: Partial<SearchParamsParsed>): ThunkActionRoot =>
	(_, getState) => {
		// dont get search from window as this could be stale due to javascript cycle
		const { hash, pathname } = window.location;
		// recreate from search
		const currentParams: SearchParamsParsed = {
			[SearchParamKeys.ACTIVE_COLUMNS]: Object.keys(getState().content.activeColumns).filter(
				(col) => col,
			),
		};
		const newSearch = assembleSearchParams({
			...currentParams,
			...paramsToUpdate,
		});
		updateURL(pathname, hash, newSearch);
	};

const pageRoutes: Array<
	SafeRoute<RouteActionReturn, AppRouteContext, RawCommon & { clear: string }>
> = [
	{
		path: `/`,
		// action: () => ({ redirect: `/${enabledModulesConfig.defaultPage}` }),
		action: () => ({ redirect: `/${enabledModulesConfig.defaultPage}` }),
	},
];

if (process.env.MESSAGES_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.MESSAGES}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.MESSAGES });
			await Promise.all([
				import(/* webpackChunkName: "messages-page" */ '../components/messages-page'),
				dispatch(fetchMessages()),
			]);

			dispatch({
				type: SET_ACTIVE_COLUMNS,
				activeColumns: convertActiveColumnParamsIntoRecord(
					searchParams[SearchParamKeys.ACTIVE_COLUMNS],
				),
			});

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.NEW_MESSAGE}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.NEW_MESSAGE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "new-message" */ '../components/new-message'),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.EDIT_MESSAGE}/:id`,
		action: async ({ dispatch, getState, searchParams }, { id }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.EDIT_MESSAGE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "edit-message" */ '../components/edit-message'),
				dispatch(fetchMessageById(id)),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.FLOODGATES_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.FLOODGATES}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.FLOODGATES });
			await Promise.all([
				import(/* webpackChunkName: "floodgates-page" */ '../components/floodgates-page'),
				dispatch(fetchFloodgates()),
			]);

			dispatch({
				type: SET_ACTIVE_COLUMNS,
				activeColumns: convertActiveColumnParamsIntoRecord(
					searchParams[SearchParamKeys.ACTIVE_COLUMNS],
				),
			});

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.NEW_FLOODGATE}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			await Promise.all([
				import(/* webpackChunkName: "new-floodgate" */ '../components/new-floodgate'),
			]);
			dispatch({ type: SET_PAGE, page: AppPages.NEW_FLOODGATE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.EDIT_FLOODGATE}/:id`,
		action: async ({ dispatch, getState, searchParams }, { id }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			await import(/* webpackChunkName: "edit-floodgate" */ '../components/edit-floodgate');
			dispatch({ type: SET_PAGE, page: AppPages.EDIT_FLOODGATE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([dispatch(fetchFloodgateById(id))]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.CAMPAIGNS_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.CAMPAIGNS}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.CAMPAIGNS });
			await Promise.all([
				import(/* webpackChunkName: "campaign-page" */ '../components/campaign-page'),
				dispatch(fetchCampaigns()),
			]);
			dispatch({
				type: SET_ACTIVE_COLUMNS,
				activeColumns: convertActiveColumnParamsIntoRecord(
					searchParams[SearchParamKeys.ACTIVE_COLUMNS],
				),
			});

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.NEW_CAMPAIGN}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.NEW_CAMPAIGN });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "new-campaign" */ '../components/new-campaign'),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.EDIT_CAMPAIGN}/:id`,
		action: async ({ dispatch, getState, searchParams }, { id }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.EDIT_CAMPAIGN });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "edit-campaign" */ '../components/edit-campaign'),
				dispatch(fetchCampaignById(id)),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.HELP_PAGES_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.HELP_PAGES}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.HELP_PAGES });
			await Promise.all([
				import(/* webpackChunkName: "help-pages-page" */ '../components/help-pages-page'),
				dispatch(fetchHelpPages()),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.NEW_HELP_PAGE}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.NEW_HELP_PAGE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "new-help-page" */ '../components/new-help-page'),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.EDIT_HELP_PAGE}/:id`,
		action: async ({ dispatch, getState, searchParams }, { id }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.EDIT_HELP_PAGE });
			dispatch({
				type: SET_MUTATE_CONTENT_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "edit-help-page" */ '../components/edit-help-page'),
				dispatch(fetchHelpPageById(id)),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.DASHBOARDS_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.DASHBOARDS}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.DASHBOARDS });
			await Promise.all([
				import(/* webpackChunkName: "dashboards-page" */ '../components/dashboards-page'),
				dispatch(fetchDashboards()),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.NEW_DASHBOARD}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.NEW_DASHBOARD });
			dispatch({
				type: SET_MUTATE_DASHBOARD_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "new-dashboard" */ '../components/new-dashboard'),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
	pageRoutes.push({
		path: `/${AppPages.EDIT_DASHBOARD}/:id`,
		action: async ({ dispatch, getState, searchParams }, { id }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.EDIT_DASHBOARD });
			dispatch({
				type: SET_MUTATE_DASHBOARD_STATUS,
				status: RequestStatus.UNSUBMITTED,
			});

			await Promise.all([
				import(/* webpackChunkName: "edit-dashboard" */ '../components/edit-dashboard'),
				dispatch(fetchDashboardById(id)),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.PLOWS_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.PLOWS}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.PLOWS });
			await Promise.all([
				import(/* webpackChunkName: "plows-page" */ '../components/plows-page'),
				dispatch(fetchPlows()),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

if (process.env.DEACTIVATE_LAYERS_ENABLED) {
	pageRoutes.push({
		path: `/${AppPages.DEACTIVATE_LAYERS}`,
		action: async ({ dispatch, getState, searchParams }) => {
			if (!isLoggedIn(getState())) {
				void dispatch(navigateModal(ModalView.LOGIN));
			}
			dispatch({ type: SET_PAGE, page: AppPages.DEACTIVATE_LAYERS });
			await Promise.all([
				import(/* webpackChunkName: "deactivate-page" */ '../components/deactivate-page'),
			]);

			const { hash, pathname } = window.location;
			const newSearch = assembleSearchParams(searchParams);
			updateURL(pathname, newSearch, hash);

			return true;
		},
	});
}

function errorHandler(
	error: RouteError,
	{ dispatch }: RouteContext<RouteActionReturn, AppRouteContext> & AppRouteContext,
) {
	console.error('Routing error', error);
	dispatch({ type: SET_PAGE, page: undefined });
	return true;
}

const appPageRouter = new UniversalRouter(pageRoutes as Route<RouteActionReturn, AppRouteContext>, {
	errorHandler: errorHandler as ErrorHandler<RouteActionReturn>,
});

function isRedirect(routeResult?: RouteActionReturn): routeResult is { redirect: string } {
	return isObject(routeResult) && 'redirect' in routeResult;
}

export const navigatePage =
	(pathname: string, searchParams?: SearchParamsParsed): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState) => {
		const defaultParams = { ...searchParams };

		const context: ResolveContext & AppRouteContext = {
			pathname,
			dispatch,
			getState,
			searchParams: defaultParams,
		};

		const resolvedRoute = await appPageRouter.resolve(context);

		if (isRedirect(resolvedRoute)) {
			window.history.replaceState({}, '', resolvedRoute.redirect);
			await dispatch(navigatePage(resolvedRoute.redirect));
			return;
		}

		if (window.location.pathname !== pathname) {
			const newUrl = `${pathname}${window.location.search}${window.location.hash}`;
			window.history.pushState({}, '', newUrl);
		}
	};

export const routingApp =
	(href: string): ThunkActionRoot =>
	async (dispatch) => {
		const origin = window.location.origin || `${window.location.protocol}//${window.location.host}`;
		const normHref = new URL(!href.startsWith(origin) ? `${origin}${href}` : href);
		const { pathname } = normHref;
		const searchParams = parseSearchParams(normHref.search);
		await Promise.all([
			dispatch(navigateModal(normHref.hash.substring(1))),
			dispatch(navigatePage(pathname, searchParams)),
		]);
	};
