import { createSelector } from 'reselect';
import { firstBy } from 'thenby';
import { RootAction, RootState } from '../store';

import {
	SET_ACTIVE_COLUMNS,
	SET_CAMPAIGNS,
	SET_FLOODGATES,
	SET_MESSAGES,
	SET_HELP_PAGES,
	SET_GET_CONTENT_STATUS,
	SET_MUTATE_CONTENT_STATUS,
	SET_MUTATE_CONTENT,
	SET_GET_ONE_CONTENT_STATUS,
	DELETE_MESSAGE,
	DELETE_FLOODGATE,
	DELETE_CAMPAIGN,
	DELETE_HELP_PAGE,
} from '../actions/content';

import { Content, Status } from '../../typings/schema.gen';
import { RequestStatus } from '../constants';
import { sortyBySequence } from './dashboard';

export type ActiveColumns = Partial<Record<keyof Content, boolean>>;

export function getActiveColumnCount(
	resourceKeys: string[],
	activeColumns?: ActiveColumns,
): number {
	if (!activeColumns) {
		return 0;
	}
	let count = 0;
	let k: keyof ActiveColumns;
	for (k in activeColumns) {
		if (activeColumns[k] && resourceKeys.includes(k)) {
			count += 1;
		}
	}
	return count;
}

export type ContentState = {
	messages: Content[];
	floodgates: Content[];
	campaigns: Content[];
	helpPages: Content[];
	activeColumns: ActiveColumns;
	getContentStatus: RequestStatus;
	getOneContentStatus: RequestStatus;
	mutateContentStatus: RequestStatus;
	mutateContent?: Content;
};

export const CONTENT_STATE_INITIAL: ContentState = {
	messages: [],
	floodgates: [],
	campaigns: [],
	helpPages: [],
	activeColumns: {
		id: true,
		title: true,
		status: true,
		startTime: true,
		endTime: true,
		createdBy: false,
		createdTime: false,
		lastModifiedBy: true,
		lastModifiedTime: true,
		content: true,
	},
	getContentStatus: RequestStatus.UNSUBMITTED,
	getOneContentStatus: RequestStatus.UNSUBMITTED,
	mutateContentStatus: RequestStatus.UNSUBMITTED,
};

export const content = (
	state: ContentState = CONTENT_STATE_INITIAL,
	action: RootAction,
): ContentState => {
	if (action.type === SET_ACTIVE_COLUMNS) {
		return {
			...state,
			activeColumns: action.activeColumns,
		};
	}
	if (action.type === SET_MESSAGES) {
		return {
			...state,
			messages: action.messages,
		};
	}
	if (action.type === SET_FLOODGATES) {
		return {
			...state,
			floodgates: action.floodgates,
		};
	}
	if (action.type === SET_CAMPAIGNS) {
		return {
			...state,
			campaigns: action.campaigns,
		};
	}
	if (action.type === SET_HELP_PAGES) {
		return {
			...state,
			helpPages: action.helpPages,
		};
	}
	if (action.type === SET_GET_CONTENT_STATUS) {
		return {
			...state,
			getContentStatus: action.status,
			messages: action.status === RequestStatus.LOADING ? [] : [...state.messages],
			campaigns: action.status === RequestStatus.LOADING ? [] : [...state.campaigns],
			mutateContent: action.status === RequestStatus.LOADING ? undefined : state.mutateContent,
		};
	}
	if (action.type === SET_GET_ONE_CONTENT_STATUS) {
		return {
			...state,
			getOneContentStatus: action.status,
			mutateContent: action.status === RequestStatus.LOADING ? undefined : state.mutateContent,
		};
	}
	if (action.type === SET_MUTATE_CONTENT_STATUS) {
		return {
			...state,
			mutateContentStatus: action.status,
		};
	}
	if (action.type === SET_MUTATE_CONTENT) {
		return {
			...state,
			mutateContent: action.content,
		};
	}
	if (action.type === DELETE_MESSAGE) {
		return {
			...state,
			messages: state.messages.filter((m) => m.id !== action.id),
		};
	}
	if (action.type === DELETE_FLOODGATE) {
		return {
			...state,
			floodgates: state.floodgates.filter((f) => f.id !== action.id),
		};
	}
	if (action.type === DELETE_CAMPAIGN) {
		return {
			...state,
			campaigns: state.campaigns.filter((m) => m.id !== action.id),
		};
	}
	if (action.type === DELETE_HELP_PAGE) {
		return {
			...state,
			helpPages: state.helpPages.filter((m) => m.id !== action.id),
		};
	}

	return state;
};

const sortPriority: Record<Status, number> = {
	[Status.Active]: 1,
	[Status.Scheduled]: 2,
	[Status.Expired]: 3,
};

function sortByStatus(m1: Content, m2: Content): number {
	if (!m1.status) {
		return -1;
	}
	if (!m2.status) {
		return 1;
	}
	return sortPriority[m1.status] - sortPriority[m2.status];
}

export function sortByCreateTime(
	m1: { createdTime?: string | null },
	m2: { createdTime?: string | null },
): number {
	if (!m1.createdTime) {
		return -1;
	}
	if (!m2.createdTime) {
		return 1;
	}
	const m1Date = new Date(m1.createdTime);
	const m2Date = new Date(m2.createdTime);
	return m1Date > m2Date ? -1 : 1;
}

function defaultSortResource(resources: Content[]): Content[] {
	return resources.sort(firstBy(sortByStatus).thenBy(sortByCreateTime));
}

function sortResourcesBySequence(resources: Content[]): Content[] {
	return resources.sort(sortyBySequence);
}

export const getDefaultSortedMessages = createSelector(
	(state: RootState) => state.content.messages,
	(unsortedMessages): Content[] => defaultSortResource(unsortedMessages),
);

export const getDefaultSortedFloodgates = createSelector(
	(state: RootState) => state.content.floodgates,
	(unsortedFloodgates): Content[] => defaultSortResource(unsortedFloodgates),
);

export const getDefaultSortedCampaigns = createSelector(
	(state: RootState) => state.content.campaigns,
	(unsortedCampaigns): Content[] => defaultSortResource(unsortedCampaigns),
);

export const getDefaultSortedHelpPages = createSelector(
	(state: RootState) => state.content.helpPages,
	(unsortedCampaigns): Content[] => sortResourcesBySequence(unsortedCampaigns),
);
