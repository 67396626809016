import { Mutation, Query } from '../../typings/schema.gen';
import { GraphQLResponse, GraphQLError } from '../../typings/graphql-response';
import { apiConfig } from '../../config';
import { getAuthUser } from '../actions/getAuthUser';

export type GraphQLQuery = { query: string; variables?: Record<string, unknown> };

export type GraphQLData = Partial<Query & Mutation>;

export function handleGraphQLErrors(errors: GraphQLError[]): void {
	if (errors.length) {
		throw new Error(JSON.stringify(errors));
	}
}

export default async function fetchGql(
	body: GraphQLQuery | GraphQLQuery[],
	method: 'GET' | 'POST' = 'POST',
	signal?: AbortSignal,
	endpoint = apiConfig.graphql,
): Promise<{ data: GraphQLData; errors: GraphQLError[] }> {
	let response;
	try {
		let endpointQuery = endpoint;
		if (method === 'GET') {
			if (Array.isArray(body)) {
				throw new Error('Batching not supported for GET requests');
			}
			endpointQuery += `?query=${encodeURI(body.query)}`;

			if (body.variables) {
				endpointQuery += `&variables=${encodeURI(JSON.stringify(body.variables))}`;
			}
		}

		const authUser = getAuthUser();
		const jwt = authUser?.authToken;

		const promise = fetch(endpointQuery, {
			method,
			headers: {
				'Content-Type': 'application/json',
				...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
			},
			body: method === 'POST' ? JSON.stringify(body) : undefined,
			signal,
		});
		response = await promise;
		if (!response.ok) {
			// True for all non-200 response codes
			const errorMsg = `Server response: ${response.status}`;
			throw new Error(errorMsg);
		}
		try {
			let json = (await response.json()) as GraphQLResponse | GraphQLResponse[];
			if (!Array.isArray(json)) {
				json = [json];
			}
			const fullResponse = json.reduce(
				(accumulator, queryResponse) => {
					const data = {
						...accumulator.data,
						...queryResponse.data,
					};

					const errors = [...accumulator.errors];
					if (queryResponse.errors) {
						queryResponse.errors.forEach((error) => {
							errors.push(error);
						});
					}
					return {
						data,
						errors,
					};
				},
				{ data: {} as GraphQLData, errors: [] },
			);
			return fullResponse as { data: GraphQLData; errors: GraphQLError[] };
		} catch (error) {
			if ((error as Error)?.name !== 'AbortError') {
				// In the unlikely event that invalid json, or json of an unexpected basic structure is returned.
				const errorMsg = `Data processing error: ${JSON.stringify(error)}`;
				console.error(errorMsg);
				throw new Error(errorMsg);
			}
		}
	} catch (error) {
		if ((error as Error)?.name !== 'AbortError') {
			throw new Error(error);
		}
		// Only catches network failures
		// no-op - offline listener should detect network issues
		console.info(error);
	}

	// Network timeout or abort
	return { data: {} as GraphQLData, errors: [] };
}
