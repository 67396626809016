import { Action } from 'redux';
import { ThunkActionRoot } from '../store';
import { ModalView, NAVIGATE_MODAL } from '../reducers/modal';

interface NavigateModal extends Action<typeof NAVIGATE_MODAL> {
	modalView?: ModalView | null;
}

export type ModalActions = NavigateModal;

export const setModalOpen =
	(modalView: ModalView): ThunkActionRoot =>
	(dispatch) => {
		dispatch({ type: NAVIGATE_MODAL, modalView });
	};

export const hideModal = (): ThunkActionRoot => (dispatch) => {
	dispatch({ type: NAVIGATE_MODAL, modalView: null });
};
