import { createSelector } from 'reselect';

import { PlowVehicle } from '@typings/schema.gen';
import { RootAction, RootState } from '@src/store';

import {
	SET_GET_PLOWS_STATUS,
	SET_PLOWS,
	SET_UPDATE_PLOW_LOADING,
	SET_UPDATE_PLOW_SUCCESS,
	SET_UPDATE_PLOW_ERROR,
} from '@src/actions/plow';
import { RequestStatus } from '@src/constants';

export type PlowState = {
	plows: Record<string, PlowVehicle>;
	getPlowsStatus: RequestStatus;
	loadingPlows: Set<string>;
	erroredPlows: Set<string>;
};

export const PLOW_STATE_INITIAL: PlowState = {
	plows: {},
	getPlowsStatus: RequestStatus.UNSUBMITTED,
	loadingPlows: new Set(),
	erroredPlows: new Set(),
};

export const plow = (state: PlowState = PLOW_STATE_INITIAL, action: RootAction): PlowState => {
	if (action.type === SET_PLOWS) {
		const plows = action.plows.reduce((acc, item) => {
			const { id } = item;
			acc[id] = item;
			return acc;
		}, {} as Record<string, PlowVehicle>);

		return {
			...state,
			plows,
			loadingPlows: PLOW_STATE_INITIAL.loadingPlows,
			erroredPlows: PLOW_STATE_INITIAL.erroredPlows,
		};
	}
	if (action.type === SET_GET_PLOWS_STATUS) {
		return {
			...state,
			getPlowsStatus: action.status,
		};
	}
	if (action.type === SET_UPDATE_PLOW_LOADING) {
		const currentLoadingPlows = new Set(state.loadingPlows);
		currentLoadingPlows.add(action.id);
		return {
			...state,
			loadingPlows: currentLoadingPlows,
		};
	}
	if (action.type === SET_UPDATE_PLOW_ERROR) {
		const currentLoadingPlows = new Set(state.loadingPlows);
		const currentErroredPlows = new Set(state.erroredPlows);
		currentLoadingPlows.delete(action.id);
		currentErroredPlows.add(action.id);
		return {
			...state,
			loadingPlows: currentLoadingPlows,
			erroredPlows: currentErroredPlows,
		};
	}
	if (action.type === SET_UPDATE_PLOW_SUCCESS) {
		const { id, visible } = action;
		const currentLoadingPlows = new Set(state.loadingPlows);
		currentLoadingPlows.delete(id);
		const plowsCopy = { ...state.plows };
		plowsCopy[id].visible = visible;

		return {
			...state,
			loadingPlows: currentLoadingPlows,
			plows: plowsCopy,
		};
	}
	return state;
};

function sortById(m1: PlowVehicle, m2: PlowVehicle): number {
	if (m1.id < m2.id) {
		return -1;
	}
	if (m1.id > m2.id) {
		return 1;
	}
	return 0;
}

function defaultSortPlow(resources: PlowVehicle[]): PlowVehicle[] {
	return resources.sort(sortById);
}

export const getDefaultSortedPlows = createSelector(
	(state: RootState) => Object.values(state.plow.plows),
	(unsortedPlows): PlowVehicle[] => defaultSortPlow(unsortedPlows),
);
