import { MiddlewareRoot } from '../store';

export const middlewareErrorCatcher: MiddlewareRoot =
	({ getState }) =>
	(next) =>
	(action) => {
		const state = getState();
		try {
			return next(action);
		} catch (err) {
			console.error(err, action, state);
			return next(action);
		}
	};

export default middlewareErrorCatcher;
