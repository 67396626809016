import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html, PropertyValues } from 'lit';
import { styleMap } from 'lit/directives/style-map';
import { Instance, Placement, createPopper } from '@popperjs/core';
import StylesMenu from '../../styles/styles-menu';

export enum PopperOverflow {
	VERTICLE = 'verticle',
	HORIZONTAL = 'horizontal',
	ALL = 'all',
}

export enum PopperPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
	LEFT = 'left',
	RIGHT = 'right',
}

@customElement('popper-container')
export class PopperContainer extends LitElement {
	@property({ type: String })
	public position: PopperPosition = PopperPosition.BOTTOM;

	@property({ type: String })
	public overflow: PopperOverflow = PopperOverflow.ALL;

	@property({ type: Boolean, attribute: 'is-flip' })
	public isFlip = false;

	@property({ type: Boolean, attribute: 'is-visible' })
	public isVisible = false;

	private _element: HTMLElement = this;

	public set element(element: HTMLElement) {
		this._element = element;
	}

	public get element(): HTMLElement {
		return this._element;
	}

	public popper?: Instance;

	public static get styles(): CSSResultArray {
		return [
			StylesMenu,
			// language=CSS
			css`
				:host {
					display: block;
					position: relative;
					--tail-color: white;
					--tail-border-color: rgb(var(--silver));
					--tail-border-width: var(--border-width);
				}

				#popper-container {
					z-index: 5;
					padding: var(--gap);
					position: absolute;
				}

				#arrow {
					position: absolute;
					height: 0.875rem;
					width: 0.875rem;
					z-index: 5;
				}

				#arrow::before {
					content: '';
					left: 0;
					height: 0.875rem;
					width: 0.875rem;
					background-color: var(--tail-color);
					border: var(--tail-border-width) solid var(--tail-border-color);
					position: absolute;
					clip-path: polygon(0 50%, 50% 100%, 100% 50%);
				}

				#popper-container[data-popper-reference-hidden],
				#popper-container[x-out-of-boundaries] {
					visibility: hidden;
				}

				#popper-container[data-popper-placement='bottom'] #arrow,
				#popper-container[x-placement='bottom'] #arrow {
					top: calc(var(--gap) / 2);
				}

				#popper-container[data-popper-placement='bottom'] #arrow::before {
					border-right: none;
					border-bottom: none;
					transform: rotate(180deg);
				}

				#popper-container[data-popper-placement='top'] #arrow,
				#popper-container[x-placement='top'] #arrow {
					bottom: calc(var(--gap) / 2);
				}

				#popper-container[data-popper-placement='top'] #arrow::before {
					border-left: none;
					border-top: none;
					transform: rotate(0deg);
				}

				#popper-container[data-popper-placement='left'] #arrow,
				#popper-container[x-placement='left'] #arrow {
					right: calc(var(--gap) / 2);
				}

				#popper-container[data-popper-placement='left'] #arrow::before {
					border-bottom: none;
					border-left: none;
					transform: rotate(90deg);
				}

				#popper-container[data-popper-placement='right'] #arrow,
				#popper-container[x-placement='right'] #arrow {
					left: calc(var(--gap) / 2);
				}

				#popper-container[data-popper-placement='right'] #arrow::before {
					border-top: none;
					border-right: none;
					transform: rotate(270deg);
				}
			`,
		];
	}

	protected render(): TemplateResult {
		return html`
			<slot name="toggler"></slot>
			<div id="popper-container" style=${styleMap({ display: this.isVisible ? 'block' : 'none' })}>
				<slot id="contents"></slot>
				<div id="arrow"></div>
			</div>
		`;
	}

	protected applyPopper(): void {
		let placement: Placement[];
		switch (this.overflow) {
			case PopperOverflow.VERTICLE:
				placement = ['left', 'right'];
				break;
			case PopperOverflow.HORIZONTAL:
				placement = ['top', 'bottom'];
				break;
			default:
				placement = ['top', 'right', 'bottom', 'left'];
		}
		const popperElement = this.renderRoot.querySelector('#popper-container') as HTMLDivElement;
		this.popper = createPopper(this._element, popperElement, {
			placement: this.position,
			modifiers: [
				{
					name: 'arrow',
					options: {
						element: '#arrow',
						padding: 4, // 1rem * .25 border-radius * 16px base font size
					},
				},
				{
					name: 'preventOverflow',
					options: {
						placement,
						padding: 0,
					},
				},
				{
					name: 'flip',
					options: {
						enabled: this.isFlip,
						padding: 0,
					},
				},
				{
					name: 'keepTogether',
					options: {
						enabled: true,
					},
				},
				{
					name: 'hide',
					options: {
						enabled: true,
					},
				},
			],
		});
	}

	public willUpdate(changed: PropertyValues): void {
		if (changed.has('position') && this.popper) {
			// Works for both versions of Popper. Bad definition file?
			void this.popper.setOptions({ placement: this.position });
		}
		if (changed.has('isVisible') && this.isVisible) {
			this.applyPopper();
		}
		if (changed.has('isVisible') && !this.isVisible && this.popper) {
			this.popper.destroy();
		}
	}
}

export default PopperContainer;
