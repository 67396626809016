const matchNonAlphaNumeric = /[^a-z0-9\s]/gi;
const matchSpaces = /\s/gi;
/**
 * @param str
 */
function slugify(str: string, spaceReplacement = '-'): string {
	return str
		.replace(matchNonAlphaNumeric, '') //	blank out non alphanumeric characters
		.replace(matchSpaces, spaceReplacement) // replace spaces with hyphens
		.toLowerCase();
}
export default slugify;
