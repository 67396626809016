export default async function tryFetch<T>(
	fetcher: () => Promise<T>,
	maxAttempts = 3,
	intervalMillis = 1000,
): Promise<T> {
	try {
		return await fetcher();
	} catch (error) {
		if (maxAttempts > 1) {
			console.error('Fetch failed. Reattempting...', error);
			await new Promise((resolve) => setTimeout(resolve, intervalMillis));
			return tryFetch(fetcher, maxAttempts - 1, intervalMillis + intervalMillis);
		}

		console.error('Fetch failed all attempts. Please refresh and try again.', error);
		throw error;
	}
}
