/**
 * Tagged template literal that strips tabs, spaces, and new lines from query.
 * Mostly useful because it allows us to identify graphql queries in the editor
 * for syntax highlighting, autocomplete, and type safety.
 *
 * @param strings
 * @param values
 */
const gql = function gql(strings: TemplateStringsArray, ...values: string[]): string {
	const ret = strings.reduce((result, string, i) => `${result}${string}${values[i] || ''}`, '');
	// Replace multiple spaces, tabs, new lines, with one space
	return ret.replace(/\s+\s+/g, ' ').trim();
};

export default gql;
