export enum CameraViewType {
	EXTERNAL_PAGE = 'EXTERNAL_PAGE',
	STILL_IMAGE = 'STILL_IMAGE',
	MJPEG = 'MJPEG',
	FLASH = 'FLASH',
	WMP = 'WMP',
}

export type CameraView = {
	name: string;
	url: string;
	type: CameraViewType;
	videoPreviewUrl: string;
};

export type Camera = {
	id: number;
	name: string;
	location: {
		routeId: string;
	};
	views: CameraView[];
};

export type Sign = {
	id: string;
	idForDisplay: string;
	name: string;
};

export type CameraViewInfo = {
	cameraId: number;
	cameraName: string;
	cameraViewName: string;
	routeId: string;
	thumbnailUrl: string;
};
