import UniversalRouter, {
	ErrorHandler,
	ResolveContext,
	Route,
	RouteContext,
	RouteError,
} from 'universal-router';
import { RootState, ThunkActionRoot } from '../store';
import { AppPages, RequestStatus } from '../constants';
import { AppRouteContext, RouteActionReturn, SafeRoute } from './routing';
import { ModalView } from '../reducers/modal';
import { hideModal, setModalOpen } from './modal';
import {
	fetchCampaignById,
	fetchHelpPageById,
	fetchMessageById,
	fetchFloodgateById,
	SET_MUTATE_CONTENT_STATUS,
} from './content';
import {
	fetchAllCameras,
	fetchAllSigns,
	fetchDashboardById,
	SET_MUTATE_DASHBOARD_STATUS,
} from './dashboard';

type AllowedParams = {
	id: string;
};

const hashRoutes: Array<SafeRoute<RouteActionReturn, AppRouteContext, AllowedParams>> = [
	{
		path: ModalView.LOGIN,
		async action({ dispatch, getState }) {
			if (getState().user.user) {
				return { redirect: `` };
			}
			await import(/* webpackChunkName: "login-modal" */ '../components/modal/login-modal');
			dispatch(setModalOpen(ModalView.LOGIN));
			return true;
		},
	},
	{
		path: ModalView.FORGOT_PASSWORD,
		async action({ dispatch }) {
			await import(
				/* webpackChunkName: "forgot-password-modal" */ '../components/modal/forgot-password-modal'
			);
			dispatch(setModalOpen(ModalView.FORGOT_PASSWORD));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_MESSAGE}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-message-modal" */ '../components/modal/delete-message-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_MESSAGE));
			dispatch(fetchMessageById(id));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_FLOODGATE}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-floodgate-modal" */ '../components/modal/delete-floodgate-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_FLOODGATE));
			dispatch(fetchFloodgateById(id));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_CAMPAIGN}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-campaign-modal" */ '../components/modal/delete-campaign-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_CAMPAIGN));
			dispatch(fetchCampaignById(id));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_CAMPAIGN}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-campaign-modal" */ '../components/modal/delete-campaign-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_CAMPAIGN));
			dispatch(fetchCampaignById(id));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_HELP_PAGE}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-campaign-modal" */ '../components/modal/delete-help-page-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_HELP_PAGE));
			dispatch(fetchHelpPageById(id));
			return true;
		},
	},
	{
		path: `${ModalView.DELETE_DASHBOARD}/:id`,
		async action({ dispatch }, { id }) {
			dispatch({ type: SET_MUTATE_DASHBOARD_STATUS, status: RequestStatus.UNSUBMITTED });

			await import(
				/* webpackChunkName: "delete-dashboard-modal" */ '../components/modal/delete-dashboard-modal'
			);

			dispatch(setModalOpen(ModalView.DELETE_DASHBOARD));
			dispatch(fetchDashboardById(id));
			return true;
		},
	},
	{
		path: `${ModalView.EDIT_LIST}`,
		async action({ dispatch, getState }) {
			if (
				!getState().dashboard.mutateDashboard &&
				getState().routing.page !== AppPages.NEW_DASHBOARD
			) {
				return { redirect: `` };
			}
			await import(
				/* webpackChunkName: "event-list-modal" */ '../components/modal/event-list-modal'
			);

			dispatch(setModalOpen(ModalView.EDIT_LIST));
			return true;
		},
	},
	{
		path: `${ModalView.EDIT_GALLERY}`,
		async action({ dispatch, getState }) {
			if (
				!getState().dashboard.mutateDashboard &&
				getState().routing.page !== AppPages.NEW_DASHBOARD
			) {
				return { redirect: `` };
			}
			await import(/* webpackChunkName: "gallery-modal" */ '../components/modal/gallery-modal');

			dispatch(setModalOpen(ModalView.EDIT_GALLERY));

			// Re-fetch if they failed prior to this call
			const { getCamerasStatus, getSignsStatus } = getState().dashboard;

			if (getCamerasStatus === RequestStatus.ERROR) {
				dispatch(fetchAllCameras());
			}

			if (getSignsStatus === RequestStatus.ERROR) {
				dispatch(fetchAllSigns());
			}

			return true;
		},
	},
];

function errorHandler(
	error: RouteError,
	{ path }: RouteContext<RouteActionReturn, AppRouteContext> & AppRouteContext,
) {
	// TODO: real error handling
	// eslint-disable-next-line no-console
	console.error(`error routing hash: "${path}" - `, error);
	return null;
}

const hashRouter = new UniversalRouter(hashRoutes as Route<RouteActionReturn, AppRouteContext>, {
	errorHandler: errorHandler as ErrorHandler<RouteActionReturn>,
});

export const navigateModal =
	(hash?: string | null): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState: () => RootState) => {
		if (hash) {
			const part = `${window.location.pathname}${window.location.search}#${hash}`;
			window.history.replaceState({}, '', part);

			const context: ResolveContext & Omit<AppRouteContext, 'searchParams'> = {
				pathname: hash,
				dispatch,
				getState,
			};
			const resolveRoute = await hashRouter.resolve(context);

			if (resolveRoute && typeof resolveRoute === 'object' && 'redirect' in resolveRoute) {
				await dispatch(navigateModal(resolveRoute.redirect));
			}
		} else {
			window.history.replaceState({}, '', `${window.location.pathname}${window.location.search}`);
			dispatch(hideModal());
		}
	};

export default navigateModal;
