import { TemplateResult, LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { store } from '@src/store';
import { routingApp } from '@src/actions/routing';
import { fetchAllCameras, fetchAllSigns } from '@src/actions/dashboard';
import { getAuthUser, decodeAuthToken } from '@src/actions/getAuthUser';
import { SET_USER, logout, isCurrentUserExpired } from '@src/actions/user';

import { installRouter } from '@src/util/install-router';

import './navigation/navigation-bar';
import './main-view';
import './modal/modal-container';

@customElement('app-shell')
export class AppShell extends LitElement {
	public constructor() {
		super();

		try {
			const user = getAuthUser();
			store.dispatch(fetchAllCameras());
			store.dispatch(fetchAllSigns());
			store.dispatch({ type: SET_USER, user });

			if (user?.authToken) {
				const expiresMillis = decodeAuthToken(user.authToken).exp * 1000;
				setTimeout(() => {
					if (isCurrentUserExpired()) {
						store.dispatch(logout());
					}
				}, expiresMillis - Date.now());
			}
		} catch (error) {
			store.dispatch(logout());
		}

		installRouter((location) => {
			store.dispatch(routingApp(location));
		});
	}

	public render(): TemplateResult {
		return html` <slot></slot> `;
	}
}

export default AppShell;
