import { createSelector } from 'reselect';
import { firstBy } from 'thenby';

import { RootAction, RootState } from '@src/store';
import {
	DELETE_DASHBOARD,
	SET_DASHBOARDS,
	SET_GET_DASHBOARD_STATUS,
	SET_GET_ONE_DASHBOARD_STATUS,
	SET_MUTATE_DASHBOARD,
	SET_MUTATE_DASHBOARD_STATUS,
	SET_EDIT_DASHBOARD_COMPONENT,
	SET_GET_CAMERAS,
	SET_GET_SIGNS,
	SET_ALL_CAMERA_VIEWS,
	SET_ALL_SIGNS,
	CLEAR_EDIT,
} from '@src/actions/dashboard';
import { sortByCreateTime } from '@src/reducers/content';

import { Dashboard, DashboardComponent } from '@typings/schema.gen';
import { RequestStatus } from '@src/constants';
import { Camera, CameraViewInfo, CameraViewType, Sign } from '@typings/tg-types';

function convertCameraToCameraViewInfos(camera: Camera): CameraViewInfo[] {
	return camera.views.map((view) => {
		const viewIsVideo =
			view.type === CameraViewType.FLASH ||
			view.type === CameraViewType.MJPEG ||
			view.type === CameraViewType.WMP;

		const thumbnailUrl = viewIsVideo && view.videoPreviewUrl ? view.videoPreviewUrl : view.url;

		return {
			cameraId: camera.id,
			cameraName: camera.name,
			routeId: camera.location.routeId,
			cameraViewName: view.name,
			thumbnailUrl,
		};
	});
}

export type DashboardState = {
	dashboards: Dashboard[];
	getDashboardStatus: RequestStatus;
	getOneDashboardStatus: RequestStatus;
	mutateDashboardStatus: RequestStatus;
	mutateDashboard?: Dashboard;
	mutateDashboardComponent?: DashboardComponent;
	mutateDashboardComponentIndex?: number;
	// other data
	getCamerasStatus: RequestStatus;
	getSignsStatus: RequestStatus;
	allCameraViews: CameraViewInfo[];
	allSigns: Sign[];
};

export const DASHBOARD_STATE_INITIAL: DashboardState = {
	dashboards: [],
	getDashboardStatus: RequestStatus.UNSUBMITTED,
	getOneDashboardStatus: RequestStatus.UNSUBMITTED,
	mutateDashboardStatus: RequestStatus.UNSUBMITTED,
	getCamerasStatus: RequestStatus.UNSUBMITTED,
	getSignsStatus: RequestStatus.UNSUBMITTED,
	allCameraViews: [],
	allSigns: [],
};

export const dashboard = (
	state: DashboardState = DASHBOARD_STATE_INITIAL,
	action: RootAction,
): DashboardState => {
	if (action.type === SET_GET_DASHBOARD_STATUS) {
		return {
			...state,
			getDashboardStatus: action.status,
			dashboards: action.status === RequestStatus.LOADING ? [] : [...state.dashboards],
			mutateDashboard: action.status === RequestStatus.LOADING ? undefined : state.mutateDashboard,
		};
	}
	if (action.type === SET_GET_ONE_DASHBOARD_STATUS) {
		return {
			...state,
			getOneDashboardStatus: action.status,
			mutateDashboard: action.status === RequestStatus.LOADING ? undefined : state.mutateDashboard,
		};
	}
	if (action.type === SET_MUTATE_DASHBOARD_STATUS) {
		return {
			...state,
			mutateDashboardStatus: action.status,
		};
	}
	if (action.type === SET_MUTATE_DASHBOARD) {
		return {
			...state,
			mutateDashboard: action.dashboard,
			mutateDashboardComponent: undefined,
			mutateDashboardComponentIndex: undefined,
		};
	}
	if (action.type === SET_DASHBOARDS) {
		return {
			...state,
			dashboards: action.dashboards,
		};
	}
	if (action.type === DELETE_DASHBOARD) {
		return {
			...state,
			dashboards: state.dashboards.filter((m) => m.id !== action.id),
		};
	}
	if (action.type === SET_EDIT_DASHBOARD_COMPONENT) {
		return {
			...state,
			mutateDashboardComponent: action.component,
			mutateDashboardComponentIndex: action.index,
		};
	}
	if (action.type === SET_GET_CAMERAS) {
		return {
			...state,
			getCamerasStatus: action.status,
		};
	}
	if (action.type === SET_GET_SIGNS) {
		return {
			...state,
			getSignsStatus: action.status,
		};
	}
	if (action.type === SET_ALL_CAMERA_VIEWS) {
		return {
			...state,
			allCameraViews: action.cameras.map(convertCameraToCameraViewInfos).flat(),
		};
	}
	if (action.type === SET_ALL_SIGNS) {
		return {
			...state,
			allSigns: action.signs,
		};
	}
	if (action.type === CLEAR_EDIT) {
		return {
			...state,
			mutateDashboardComponent: undefined,
			mutateDashboardComponentIndex: undefined,
		};
	}
	return state;
};

export function sortyBySequence(
	c1?: { sequenceNumber?: number | null } | null,
	c2?: { sequenceNumber?: number | null } | null,
): number {
	if (!c1?.sequenceNumber) {
		return -1;
	}

	if (!c2?.sequenceNumber) {
		return 1;
	}

	return c1.sequenceNumber - c2.sequenceNumber;
}

function defaultSortDashboard(resources: Dashboard[]): Dashboard[] {
	return resources.sort(firstBy(sortByCreateTime));
}

export const getDefaultSortedDashboards = createSelector(
	(state: RootState) => state.dashboard.dashboards,
	(unsortedDashboards): Dashboard[] => defaultSortDashboard(unsortedDashboards),
);
