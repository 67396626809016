import { Action } from 'redux';

import { PlowVehicle, PlowVehicleInput } from '@typings/schema.gen';
import { RequestStatus } from '@src/constants';

import { ThunkActionRoot } from '@src/store';

import fetchGql, { handleGraphQLErrors } from '@src/util/fetchGql';
import gql from '@src/util/gql';

export const SET_PLOWS = 'SET_PLOWS';
export const SET_GET_PLOWS_STATUS = 'SET_GET_PLOWS_STATUS';
export const SET_UPDATE_PLOW_LOADING = 'SET_UPDATE_PLOW_LOADING';
export const SET_UPDATE_PLOW_SUCCESS = 'SET_UPDATE_PLOW_SUCCESS';
export const SET_UPDATE_PLOW_ERROR = 'SET_UPDATE_PLOW_ERROR';

interface SetPlows extends Action<typeof SET_PLOWS> {
	plows: PlowVehicle[];
}

interface SetGetPlows extends Action<typeof SET_GET_PLOWS_STATUS> {
	status: RequestStatus;
}

interface SetRequestedPlowLoading extends Action<typeof SET_UPDATE_PLOW_LOADING> {
	id: string;
}

interface SetRequestedPlowSuccess extends Action<typeof SET_UPDATE_PLOW_SUCCESS> {
	id: string;
	visible: boolean;
}

interface SetRequestedPlowError extends Action<typeof SET_UPDATE_PLOW_ERROR> {
	id: string;
}

export type PlowActions =
	| SetPlows
	| SetGetPlows
	| SetRequestedPlowLoading
	| SetRequestedPlowSuccess
	| SetRequestedPlowError;

const ALL_PLOWS_QUERY = gql`
	query {
		getPlowVehicles {
			id
			make
			model
			year
			assetType
			esn
			visible
		}
	}
`;

export const fetchPlows = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_PLOWS_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_PLOWS_QUERY,
		});
		handleGraphQLErrors(response.errors);
		const plows = response.data.getPlowVehicles?.filter((m) => m) || [];
		dispatch({ type: SET_PLOWS, plows: plows as PlowVehicle[] });
		dispatch({ type: SET_GET_PLOWS_STATUS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_PLOWS_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

const SET_PLOW_VISIBILITY_MUTATION = gql`
	mutation ($id: ID!, $visible: Boolean!) {
		updateVisibility(id: $id, visible: $visible)
	}
`;

export const setPlowVisibility =
	(id: string, visible: boolean): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_UPDATE_PLOW_LOADING, id });

		try {
			const response = await fetchGql({
				query: SET_PLOW_VISIBILITY_MUTATION,
				variables: { id, visible },
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_UPDATE_PLOW_SUCCESS, id, visible });
		} catch (error) {
			dispatch({ type: SET_UPDATE_PLOW_ERROR, id });
			console.error(error);
		}
	};

const BATCH_SET_VISIBILITY_MUTATION = gql`
	mutation ($input: [PlowVehicleInput!]) {
		batchUpdateVisibility(input: $input)
	}
`;

export const batchSetPlowVisibility =
	(ids: string[], visible: boolean): ThunkActionRoot =>
	async (dispatch) => {
		ids.forEach((id) => {
			dispatch({ type: SET_UPDATE_PLOW_LOADING, id });
		});

		const input: PlowVehicleInput[] = ids.map((id) => ({
			id,
			visible,
		}));

		try {
			const response = await fetchGql({
				query: BATCH_SET_VISIBILITY_MUTATION,
				variables: {
					input,
				},
			});
			handleGraphQLErrors(response.errors);
			ids.forEach((id) => {
				dispatch({ type: SET_UPDATE_PLOW_SUCCESS, id, visible });
			});
		} catch (error) {
			ids.forEach((id) => {
				dispatch({ type: SET_UPDATE_PLOW_ERROR, id });
			});
			console.error(error);
		}
	};
