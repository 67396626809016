import jwtDecode from 'jwt-decode';

import { User } from '../../typings/schema.gen';

import slugify from '../util/slugify';
import SessionExpiredError from '../util/SessionExpiredError';

export const LSAuthUser = `${slugify(window.location.hostname)}-AUTH`;

interface Authtoken {
	sub: string;
	exp: number;
	iat: number;
}

export function decodeAuthToken(token: string): Authtoken {
	return jwtDecode(token);
}

export function getAuthUser(): User | undefined {
	try {
		const maybeAuthUser = window.localStorage.getItem(LSAuthUser);
		if (!maybeAuthUser) {
			return undefined;
		}

		const authUser = JSON.parse(maybeAuthUser) as User;

		const decodedAuthToken = decodeAuthToken(authUser.authToken || '');
		if (Date.now() > decodedAuthToken.exp * 1000) {
			window.localStorage.removeItem(LSAuthUser);
			throw new SessionExpiredError();
		}
		return authUser;
	} catch (error) {
		console.error(error);
		window.localStorage.removeItem(LSAuthUser);
		throw error;
	}
}

export default getAuthUser;
