import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map';
import { styleMap } from 'lit/directives/style-map';

import StylesMenu from '../../styles/styles-menu';
import containsTargetPenetrate from '../../util/contains-penetrate';

export enum DropdownPosition {
	CENTER = 'CENTER',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

@customElement('dropdown-menu')
export class DropdownMenu extends LitElement {
	@property({ type: Boolean, reflect: true })
	public expanded = false;

	@property({ type: Boolean, reflect: true, attribute: 'force-expanded' })
	public forceExpanded = false;

	/**
	 * Don't close when elements in dropdown are clicked, only outside elements close the dropdown.
	 */
	@property({ type: Boolean, reflect: true, attribute: 'no-auto-close' })
	public noAutoClose = false;

	public static get styles(): CSSResultArray {
		return [
			StylesMenu,
			// language=CSS
			css`
				:host {
					position: relative;
					display: flex;
					align-self: flex-start;
				}

				.popper {
					--tail-color: rgb(var(--off-black));
					--tail-border-width: 0;
				}
			`,
		];
	}

	protected render(): TemplateResult {
		return html`
			<popper-container
				class="popper"
				is-flip
				?is-visible=${this.expanded || this.forceExpanded}
				style=${styleMap({
					'z-index': this.expanded ? '10' : '1',
				})}
			>
				<div
					slot="toggler"
					id="toggler"
					class=${classMap({ active: this.expanded || this.forceExpanded })}
					aria-expanded=${this.expanded ? 'true' : 'false'}
				>
					<slot name="toggler"></slot>
				</div>
				<div class="menu" role="menu" part="menu">
					<slot id="options"></slot>
				</div>
			</popper-container>
		`;
	}

	public willUpdate(): void {
		if (this.expanded || this.forceExpanded) {
			this.dispatchEvent(new CustomEvent('show'));
		}
		if (!this.expanded && !this.forceExpanded) {
			this.dispatchEvent(new CustomEvent('hide'));
		}
	}

	public constructor() {
		super();
		this.onBodyClick = this.onBodyClick.bind(this);
	}

	public connectedCallback(): void {
		super.connectedCallback();
		document.addEventListener('click', this.onBodyClick);
	}

	public disconnectedCallback(): void {
		document.removeEventListener('click', this.onBodyClick);
		super.disconnectedCallback();
	}

	protected onBodyClick(e: MouseEvent): void {
		if (!this.expanded) {
			const toggler = this.renderRoot.querySelector('#toggler') as HTMLSlotElement;
			if (containsTargetPenetrate(toggler, e)) {
				this.expanded = true;
			}
		} else {
			if (!this.noAutoClose) {
				const toggler = this.renderRoot.querySelector('#toggler') as HTMLSlotElement;
				if (!containsTargetPenetrate(toggler, e)) {
					this.expanded = false;
				}
			}

			const options = this.renderRoot.querySelector('#options') as HTMLSlotElement;
			if (!containsTargetPenetrate(options, e)) {
				this.expanded = false;
			}
		}
	}
}

export default DropdownMenu;
