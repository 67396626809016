export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	JSON: { [key: string]: any };
};

export type Base64Image = {
	base64String: Scalars['String'];
	fileName: Scalars['String'];
};

export enum ContentPriority {
	LOW = 'Low',
	MEDIUM = 'Medium',
	HIGH = 'High',
}

export type Content = {
	__typename?: 'Content';
	id: Scalars['ID'];
	title: Scalars['String'];
	content: Scalars['String'];
	draft: Scalars['Boolean'];
	startTime?: Maybe<Scalars['String']>;
	endTime?: Maybe<Scalars['String']>;
	createdBy?: Maybe<Scalars['String']>;
	createdTime?: Maybe<Scalars['String']>;
	lastModifiedBy?: Maybe<Scalars['String']>;
	lastModifiedTime?: Maybe<Scalars['String']>;
	status?: Maybe<Status>;
	url?: Maybe<Scalars['String']>;
	configJson?: Maybe<Scalars['JSON']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	priority?: Maybe<ContentPriority>;
	otherContent?: Maybe<Scalars['String']>;
};

export type CreateContentInput = {
	title: Scalars['String'];
	content: Scalars['String'];
	draft: Scalars['Boolean'];
	startTime?: Maybe<Scalars['String']>;
	endTime?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
	configJson?: Maybe<Scalars['JSON']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	priority?: Maybe<ContentPriority>;
	otherContent?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
	fullname: Scalars['String'];
	email?: Maybe<Scalars['String']>;
	username: Scalars['String'];
	password: Scalars['String'];
};

export type Dashboard = {
	__typename?: 'Dashboard';
	id: Scalars['ID'];
	title: Scalars['String'];
	icon?: Maybe<Scalars['String']>;
	components?: Maybe<Array<Maybe<DashboardComponent>>>;
	createdBy?: Maybe<Scalars['String']>;
	createdTime?: Maybe<Scalars['String']>;
	lastModifiedBy?: Maybe<Scalars['String']>;
	lastModifiedTime?: Maybe<Scalars['String']>;
};

export type DashboardComponent = {
	__typename?: 'DashboardComponent';
	id: Scalars['ID'];
	dashboardId?: Maybe<Scalars['ID']>;
	type: DashboardComponentType;
	title: Scalars['String'];
	contents?: Maybe<Array<Maybe<DashboardContent>>>;
	priorityThreshold?: Maybe<Scalars['Int']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	createdBy?: Maybe<Scalars['String']>;
	createdTime?: Maybe<Scalars['String']>;
	lastModifiedBy?: Maybe<Scalars['String']>;
	lastModifiedTime?: Maybe<Scalars['String']>;
};

export type DashboardComponentInput = {
	id?: Maybe<Scalars['ID']>;
	dashboardId?: Maybe<Scalars['ID']>;
	type: DashboardComponentType;
	title: Scalars['String'];
	priorityThreshold?: Maybe<Scalars['Int']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	contents?: Maybe<Array<Maybe<DashboardContentInput>>>;
};

export enum DashboardComponentType {
	Carousel = 'CAROUSEL',
	List = 'LIST',
}

export type DashboardContent = {
	__typename?: 'DashboardContent';
	id: Scalars['ID'];
	componentId?: Maybe<Scalars['ID']>;
	type: DashboardContentType;
	configJson?: Maybe<Scalars['JSON']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	createdBy?: Maybe<Scalars['String']>;
	createdTime?: Maybe<Scalars['String']>;
	lastModifiedBy?: Maybe<Scalars['String']>;
	lastModifiedTime?: Maybe<Scalars['String']>;
};

export type DashboardContentInput = {
	id?: Maybe<Scalars['ID']>;
	componentId?: Maybe<Scalars['ID']>;
	type: DashboardContentType;
	configJson?: Maybe<Scalars['JSON']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
};

export enum DashboardContentType {
	Camera = 'CAMERA',
	Dms = 'DMS',
}

export type DashboardInput = {
	id?: Maybe<Scalars['ID']>;
	title: Scalars['String'];
	icon?: Maybe<Scalars['String']>;
	components?: Maybe<Array<Maybe<DashboardComponentInput>>>;
};

export type Mutation = {
	__typename?: 'Mutation';
	createMessage?: Maybe<Content>;
	updateMessage?: Maybe<Content>;
	deleteMessageById?: Maybe<Scalars['Boolean']>;
	createCampaign?: Maybe<Content>;
	updateCampaign?: Maybe<Content>;
	deleteCampaignById?: Maybe<Scalars['Boolean']>;
	createHelpPage?: Maybe<Content>;
	updateHelpPage?: Maybe<Content>;
	deleteHelpPageById?: Maybe<Scalars['Boolean']>;
	createUser?: Maybe<User>;
	updateUser?: Maybe<User>;
	deleteUserById?: Maybe<Scalars['Boolean']>;
	saveBase64Image?: Maybe<Scalars['String']>;
	saveDashboard?: Maybe<Dashboard>;
	deleteDashboardById?: Maybe<Scalars['Boolean']>;
	saveDashboardComponent?: Maybe<DashboardComponent>;
	deleteDashboardComponentById?: Maybe<Scalars['Boolean']>;
	updateDashboardComponentDashboardId?: Maybe<Scalars['Boolean']>;
	updateDashboardComponentRemoveDashboardId?: Maybe<Scalars['Boolean']>;
	saveDashboardContent?: Maybe<DashboardContent>;
	deleteDashboardContentById?: Maybe<Scalars['Boolean']>;
	updateDashboardContentComponentId?: Maybe<Scalars['Boolean']>;
	updateDashboardContentRemoveComponentId?: Maybe<Scalars['Boolean']>;
	updateVisibility?: Maybe<Scalars['Boolean']>;
	batchUpdateVisibility?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateMessageArgs = {
	input?: Maybe<CreateContentInput>;
};

export type MutationUpdateMessageArgs = {
	input?: Maybe<UpdateContentInput>;
};

export type MutationDeleteMessageByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationCreateCampaignArgs = {
	input?: Maybe<CreateContentInput>;
};

export type MutationUpdateCampaignArgs = {
	input?: Maybe<UpdateContentInput>;
};

export type MutationDeleteCampaignByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationCreateHelpPageArgs = {
	input?: Maybe<CreateContentInput>;
};

export type MutationUpdateHelpPageArgs = {
	input?: Maybe<UpdateContentInput>;
};

export type MutationDeleteHelpPageByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationCreateUserArgs = {
	input?: Maybe<CreateUserInput>;
};

export type MutationUpdateUserArgs = {
	input?: Maybe<UpdateUserInput>;
};

export type MutationDeleteUserByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationSaveBase64ImageArgs = {
	base64Image?: Maybe<Base64Image>;
};

export type MutationSaveDashboardArgs = {
	input?: Maybe<DashboardInput>;
};

export type MutationDeleteDashboardByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationSaveDashboardComponentArgs = {
	input?: Maybe<DashboardComponentInput>;
};

export type MutationDeleteDashboardComponentByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationUpdateDashboardComponentDashboardIdArgs = {
	id?: Maybe<Scalars['ID']>;
	dashboardId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateDashboardComponentRemoveDashboardIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationSaveDashboardContentArgs = {
	input?: Maybe<DashboardContentInput>;
};

export type MutationDeleteDashboardContentByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationUpdateDashboardContentComponentIdArgs = {
	id?: Maybe<Scalars['ID']>;
	componentId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateDashboardContentRemoveComponentIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type MutationUpdateVisibilityArgs = {
	id?: Maybe<Scalars['ID']>;
	visible?: Maybe<Scalars['Boolean']>;
};

export type MutationBatchUpdateVisibilityArgs = {
	input?: Maybe<Array<Maybe<PlowVehicleInput>>>;
};

export type Owner = {
	__typename?: 'Owner';
	displayName?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
};

export type PlowVehicle = {
	__typename?: 'PlowVehicle';
	id: Scalars['ID'];
	make?: Maybe<Scalars['String']>;
	model?: Maybe<Scalars['String']>;
	year?: Maybe<Scalars['String']>;
	assetType?: Maybe<Scalars['String']>;
	esn: Scalars['String'];
	visible?: Maybe<Scalars['Boolean']>;
};

export type PlowVehicleInput = {
	id: Scalars['ID'];
	visible: Scalars['Boolean'];
};

export type Query = {
	__typename?: 'Query';
	messageById?: Maybe<Content>;
	floodgateById?: Maybe<Content>;
	messages?: Maybe<Array<Maybe<Content>>>;
	floodgates?: Maybe<Array<Maybe<Content>>>;
	messagesByStatus?: Maybe<Array<Maybe<Content>>>;
	campaignById?: Maybe<Content>;
	campaigns?: Maybe<Array<Maybe<Content>>>;
	campaignsByStatus?: Maybe<Array<Maybe<Content>>>;
	helpPageById?: Maybe<Content>;
	helpPages?: Maybe<Array<Maybe<Content>>>;
	helpPagesByStatus?: Maybe<Array<Maybe<Content>>>;
	userById?: Maybe<User>;
	users?: Maybe<Array<Maybe<User>>>;
	authenticate?: Maybe<User>;
	imageExistsInS3?: Maybe<Scalars['Boolean']>;
	retrieveS3Objects?: Maybe<Array<Maybe<S3ObjectSummary>>>;
	dashboardById?: Maybe<Dashboard>;
	dashboards?: Maybe<Array<Maybe<Dashboard>>>;
	dashboardComponentById?: Maybe<DashboardComponent>;
	dashboardComponents?: Maybe<Array<Maybe<DashboardComponent>>>;
	dashboardContentById?: Maybe<DashboardContent>;
	dashboardContents?: Maybe<Array<Maybe<DashboardContent>>>;
	getPlowVehicles?: Maybe<Array<Maybe<PlowVehicle>>>;
	getHiddenPlowVehicleEsns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryMessageByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryMessagesArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type QueryMessagesByStatusArgs = {
	status?: Maybe<Status>;
};

export type QueryCampaignByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryCampaignsArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type QueryCampaignsByStatusArgs = {
	status?: Maybe<Status>;
};

export type QueryHelpPageByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryHelpPagesArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type QueryHelpPagesByStatusArgs = {
	status?: Maybe<Status>;
};

export type QueryUserByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryAuthenticateArgs = {
	username?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
};

export type QueryImageExistsInS3Args = {
	imageReference?: Maybe<Scalars['String']>;
};

export type QueryDashboardByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryDashboardsArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type QueryDashboardComponentByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryDashboardComponentsArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type QueryDashboardContentByIdArgs = {
	id?: Maybe<Scalars['ID']>;
};

export type QueryDashboardContentsArgs = {
	pageNumber?: Maybe<Scalars['Int']>;
	pageSize?: Maybe<Scalars['Int']>;
	sortBy?: Maybe<Scalars['String']>;
	ascending?: Maybe<Scalars['Boolean']>;
};

export type S3ObjectSummary = {
	__typename?: 'S3ObjectSummary';
	bucketName?: Maybe<Scalars['String']>;
	key?: Maybe<Scalars['String']>;
	size?: Maybe<Scalars['Int']>;
	lastModified?: Maybe<Scalars['String']>;
	storageClass?: Maybe<Scalars['String']>;
	owner?: Maybe<Owner>;
};

export enum Status {
	Active = 'ACTIVE',
	Expired = 'EXPIRED',
	Scheduled = 'SCHEDULED',
}

export type UpdateContentInput = {
	id: Scalars['ID'];
	title: Scalars['String'];
	content: Scalars['String'];
	otherContent?: Scalars['String'];
	draft: Scalars['Boolean'];
	startTime?: Maybe<Scalars['String']>;
	endTime?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
	configJson?: Maybe<Scalars['JSON']>;
	sequenceNumber?: Maybe<Scalars['Int']>;
	priority?: Maybe<ContentPriority>;
};

export type UpdateUserInput = {
	id: Scalars['ID'];
	fullname: Scalars['String'];
	email?: Maybe<Scalars['String']>;
	username: Scalars['String'];
	password: Scalars['String'];
};

export type User = {
	__typename?: 'User';
	id: Scalars['ID'];
	fullname: Scalars['String'];
	email?: Maybe<Scalars['String']>;
	username: Scalars['String'];
	authToken?: Maybe<Scalars['String']>;
};
