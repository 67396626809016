import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html } from 'lit';

import StylesContent from '../../styles/styles-content';

@customElement('navigation-account')
export class NavigationAccount extends LitElement {
	@property({ type: String }) protected initials = '';

	public static get styles(): CSSResultArray {
		return [
			StylesContent,
			css`
				.account-circle {
					border-radius: 50%;
					height: 3rem;
					width: 3rem;
					display: flex;
					text-align: center;
					justify-content: center;
					align-items: center;
					font-size: var(--large);
					text-transform: uppercase;
					background-color: rgb(var(--light-gray));
					color: rgb(var(--steel));
					cursor: pointer;
				}
			`,
		];
	}

	public render(): TemplateResult {
		return html` <div class="account-circle">${this.initials}</div> `;
	}
}

export default NavigationAccount;
