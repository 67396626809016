import { CSSResultArray, css } from 'lit';

// language=CSS
const StylesDataTable: CSSResultArray = [
	css`
		/* buttons */

		.cirle-button {
			background-color: transparent;
			border-radius: 50%;
			width: 3rem;
			height: 3rem;
			border: 0;
			margin-left: 0.5rem;
			transition: all 0.1s linear;
			cursor: pointer;
		}

		.cirle-button:hover,
		.cirle-button:focus {
			background-color: rgb(var(--silver));
			outline: none;
		}

		.arrow {
			width: 0.75rem;
			height: 0.75rem;
			fill: none;
			transition: all 0.1s linear;
			transform: rotate(-90deg);
		}

		.arrow-expanded {
			fill: rgb(var(--dark-gray));
			transform: rotate(0deg);
		}

		.row-actions {
			display: flex;
			justify-content: right;
		}

		.action-button {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.action-button > svg {
			width: 1.5rem;
			height: 1.5rem;
			fill: none;
			stroke: rgb(var(--dark-gray));
			transition: all 0.1s linear;
		}

		.filter-arrow {
			width: 0.65rem;
			height: 0.65rem;
			fill: none;
			transform: rotate(-90deg);
			margin-left: 0.25rem;
		}

		.filter-arrow-expanded {
			fill: rgb(var(--dark-gray));
			transform: rotate(0deg);
		}

		.action-button:focus,
		.filter-button:focus {
			box-shadow: 0 0 3px 1px rgb(var(--purple));
		}

		.filter-button {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}

		.filter-button-main-icon {
			width: 1.2rem;
			height: 1.2rem;
			fill: rgb(var(--dark-gray));
			stroke: rgb(var(--dark-gray));
			transition: all 0.1s linear;
		}

		/* header -base */

		.table-header {
			padding: var(--gap-half) 0 0 var(--gap-half);
			padding-left: 0.5rem;
			background-color: #fff;
			width: fit-content;
			min-width: 100%;
		}

		.table-header-row {
			display: grid;
			/* handled in render */
			/* grid-template-columns: 3.5rem repeat(6, minmax(10rem, 1fr)) 9rem; */
			grid-gap: var(--gap);
			grid-template-rows: auto;
			box-shadow: var(--box-shadow-bottom);
		}

		.sort-cell {
			color: rgb(var(--main-gray));
			display: flex;
			align-items: center;
			padding: var(--gap);
			font-size: 1rem;
		}

		.column-select-item {
			background-color: rgb(var(--off-black));
			color: rgb(var(--light-gray));
			width: 20rem;
			height: 2.5rem;
			border-top: 1px solid rgb(var(--dark-gray));
		}

		.column-select-item > * {
			padding-left: 1rem;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			margin: 0;
		}

		.column-select-item:hover {
			background-color: rgb(var(--dark-gray));
		}

		/* row - base */

		.table-row {
			display: grid;
			/* grid-template-columns: 3.5rem repeat(XXX, minmax(10rem, 1fr)) 9rem; handled in render*/
			grid-gap: var(--gap);
			grid-template-rows: auto;
			border-bottom: 1px dotted rgb(var(--silver));
			padding: var(--gap) 0;
			background-color: rgb(var(--off-white));
		}

		.table-row-expanded {
			background-color: rgb(var(--lightest-gray));
		}

		.data-cell {
			height: 3rem;
			color: rgb(var(--off-black));
			display: flex;
			align-items: top;
			margin: 0 var(--gap);
			font-size: 1rem;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.expanded-content {
			padding-top: var(--gap);
			border-top: 1px solid rgb(var(--silver));
			grid-column-start: 2;
			grid-column-end: -2;
			display: none;
		}

		.expanded-content-expanded {
			display: block;
		}

		.expand-cell {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.hidden-row {
			width: 50%;
			display: grid;
			grid-template-columns: 6rem 1fr;
			border-top: 1px dotted rgb(var(--silver));
			padding: var(--gap-half);
			margin: 0 var(--gap);
		}

		.hidden-row-header {
			color: rgb(var(--main-gray));
			font-size: var(--smaller);
		}

		.hidden-row-content {
			font-size: var(--smaller);
		}

		/* miscellaneous */
		.no-data {
			text-align: center;
			margin-top: 10rem;
			font-size: var(--largest);
			color: rgb(var(--main-gray));
		}

		.content-error {
			text-align: center;
			margin-top: 10rem;
			font-size: var(--largest);
			color: rgb(var(--danger));
		}

		.badge-holder {
			display: flex;
			gap: var(--gap);
		}
	`,
];

export default StylesDataTable;
