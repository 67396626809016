import { css } from 'lit';

// language=CSS
const StylesButton = css`
	@media screen and (prefers-reduced-motion: reduce) {
		.btn {
			transition: none;
		}
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		white-space: nowrap;
		user-select: none;
		box-sizing: border-box;
		border: 0;
		background-color: transparent;
		cursor: pointer;
		padding: var(--gap);
		font-size: 1rem;
		line-height: 1.5;
		transition: background-color 0.15s ease-out, color 0.15s ease-out, border-color 0.15s ease-out,
			fill 0.15s ease-out;
		text-decoration: none;
		margin: 0;
	}

	.btn:focus {
		outline: 0;
		box-shadow: 0 0 0.75rem 0 rgba(var(--purple), 0.7);
	}

	.btn:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	/* dark button */

	.btn-dark {
		background: rgb(var(--darkest));
		color: rgb(var(--off-white));
		fill: rgb(var(--off-white));
	}

	.btn-dark:hover {
		color: #fff;
		background: rgb(var(--dark-gray));
	}

	.btn-dark:focus {
		background: rgb(var(--dark-gray));
	}

	.btn-dark:active {
		color: #fff;
		background: rgb(var(--off-black));
	}

	.btn-dark:disabled {
		opacity: 0.5;
		cursor: not-allowed;
		background: rgb(var(--darkest));
	}

	/* light button */

	.btn-light {
		fill: rgb(var(--dark-gray));
		color: rgb(var(--dark-gray));
	}

	.btn-light:hover {
		background: rgb(var(--light-gray));
	}

	.btn-light:focus {
		background: rgb(var(--light-gray));
	}

	.btn-light:active {
		background: rgb(var(--light-gray));
	}

	.btn-light:disabled {
		opacity: 0.5;
		cursor: not-allowed;
		background: rgb(var(--dark-gray));
	}

	.btn-loading {
		transition: all 0.3s;
		transition-timing-function: ease-in;
		position: relative;
		padding-right: 2.6rem;
	}

	.circle-button {
		background-color: transparent;
		padding: 0;
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		border: 0;
		transition: all 0.1s linear;
		cursor: pointer;
	}

	.circle-button:hover,
	.circle-button:focus {
		background-color: rgb(var(--silver));
		outline: none;
	}

	.btn-outline {
		border: 1px solid rgb(var(--darkest));
	}
`;
export default StylesButton;
