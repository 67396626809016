import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html } from 'lit';

import { connect } from 'pwa-helpers';

import { AppPages } from '@src/constants';
import { store, RootState } from '../store';

@customElement('main-view')
export class MainView extends connect(store)(LitElement) {
	@property({ type: String }) protected page?: AppPages;

	public static get styles(): CSSResultArray {
		return [
			css`
				:host {
					overflow-y: scroll;
					overflow-x: auto;
				}
			`,
		];
	}

	public render(): TemplateResult {
		return html` <div>
			${this.page === AppPages.MESSAGES ? html`<messages-page></messages-page>` : undefined}
			${this.page === AppPages.NEW_MESSAGE ? html`<new-message></new-message>` : undefined}
			${this.page === AppPages.EDIT_MESSAGE ? html`<edit-message></edit-message>` : undefined}
			<!-- -->
			${this.page === AppPages.FLOODGATES ? html`<floodgates-page></floodgates-page>` : undefined}
			${this.page === AppPages.NEW_FLOODGATE ? html`<new-floodgate></new-floodgate>` : undefined}
			${this.page === AppPages.EDIT_FLOODGATE ? html`<edit-floodgate></edit-floodgate>` : undefined}
			<!--  -->
			${this.page === AppPages.CAMPAIGNS ? html`<campaigns-page></campaigns-page>` : undefined}
			${this.page === AppPages.NEW_CAMPAIGN ? html`<new-campaign></new-campaign>` : undefined}
			${this.page === AppPages.EDIT_CAMPAIGN ? html`<edit-campaign></edit-campaign>` : undefined}
			<!--  -->
			${this.page === AppPages.DASHBOARDS ? html`<dashboards-page></dashboards-page>` : undefined}
			${this.page === AppPages.NEW_DASHBOARD ? html`<new-dashboard></new-dashboard>` : undefined}
			${this.page === AppPages.EDIT_DASHBOARD ? html`<edit-dashboard></edit-dashboard>` : undefined}
			<!--  -->
			${this.page === AppPages.HELP_PAGES ? html`<help-pages-page></help-pages-page>` : undefined}
			${this.page === AppPages.NEW_HELP_PAGE ? html`<new-help-page></new-help-page>` : undefined}
			${this.page === AppPages.EDIT_HELP_PAGE ? html`<edit-help-page></edit-help-page>` : undefined}
			<!--  -->
			${this.page === AppPages.PLOWS ? html`<plows-page></plows-page>` : undefined}
			<!-- -->
			${this.page === AppPages.DEACTIVATE_LAYERS
				? html`<deactivate-page></deactivate-page>`
				: html``}
		</div>`;
	}

	public stateChanged(state: RootState): void {
		this.page = state.routing.page;
	}
}

export default MainView;
