import { CSSResultArray, css } from 'lit';

// language=CSS
const StylesContent: CSSResultArray = [
	css`
		/* Box sizing rules */
		*,
		*::before,
		*::after {
			box-sizing: border-box;
		}

		/* Remove default padding */
		ul,
		ol {
			padding: 0;
		}

		/* Remove default margin */
		body,
		h1,
		h2,
		h3,
		h4,
		p,
		ul,
		ol,
		li,
		figure,
		figcaption,
		blockquote,
		dl,
		dd {
			margin: 0;
		}

		/* headers */
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 500;
			font-size: 1rem;
			line-height: 1.5;
			margin-top: 0;
			margin-bottom: 0.25rem;
			color: inherit;
		}

		h1 {
			font-size: 1.5rem;
			line-height: 1.25;
			margin-bottom: 0.875rem;
		}

		h2 {
			font-size: 1.5rem;
			line-height: 1.25;
			margin-bottom: 0.125rem;
		}

		h3 {
			margin-bottom: 0.5rem;
		}

		/* Inherit fonts for inputs and buttons */
		input,
		button,
		textarea,
		select {
			font: inherit;
		}

		/* Remove all animations and transitions for people that prefer not to see them */
		@media (prefers-reduced-motion: reduce) {
			* {
				animation-duration: 0.01ms !important;
				animation-iteration-count: 1 !important;
				transition-duration: 0.01ms !important;
				scroll-behavior: auto !important;
			}
		}

		/* tables */
		table {
			border-collapse: collapse;
		}
		th {
			text-align: inherit;
		}

		ul:last-of-type {
			margin-bottom: 0;
		}

		strong {
			font-weight: 500;
		}

		hr {
			border: 0;
			border-top: 1px dotted rgb(var(--silver));
			margin: 0.5rem 0;
		}

		a {
			color: rgb(var(--light-purple));
			text-decoration: none;
			transition: color 0.15s ease-out, fill 0.15s ease-out, background-color 0.15s ease-out;
			-webkit-tap-highlight-color: rgba(var(--light-purple), 0.05);
		}

		a:focus {
			outline: 0;
			box-shadow: rgb(var(--purple)) 0 0 6px;
		}

		a:hover {
			color: rgb(var(--purple));
		}

		a:active {
			background: rgba(var(--purple), 0.075);
		}

		.sr-only {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			white-space: nowrap;
			border: 0;
		}

		/* placeholder styles */

		.placeholder {
			width: 80%;
			height: 1rem;
			animation: pulse 1.5s infinite ease-in-out;
			display: block;
			margin-bottom: 0.75rem;
		}

		.placeholder_last-updated {
			height: var(--smaller);
			width: 30%;
			margin: calc(var(--smaller) * 0.25) 0;
		}

		.placeholder_h2 {
			width: 66%;
			height: 1.5rem;
		}

		.placeholder_h3 {
			width: 66%;
			height: 1rem;
		}

		.placeholder_icon {
			animation: pulse 1.5s infinite ease-in-out;
			border-radius: 50%;
		}

		@keyframes pulse {
			0% {
				background-color: rgba(var(--main-gray), 0.15);
			}
			50% {
				background-color: rgba(var(--main-gray), 0.25);
			}
			100% {
				background-color: rgba(var(--main-gray), 0.15);
			}
		}

		.fadein {
			animation: fadein 0.2s;
		}

		@keyframes fadein {
			from {
				opacity: 0.15;
			}
			to {
				opacity: 1;
			}
		}
	`,
];

export default StylesContent;
