import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer';
import {
	MiddlewareAPI,
	Store,
	StoreEnhancer,
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { NavigateActions } from './actions/routing';
import { ContentActions } from './actions/content';
import { DashboardActions } from './actions/dashboard';
import { PlowActions } from './actions/plow';
import { ModalActions } from './actions/modal';
import { UserActions } from './actions/user';
import { ROUTING_STATE_INITIAL, RoutingState, routing } from './reducers/routing';
import { CONTENT_STATE_INITIAL, ContentState, content } from './reducers/content';
import { DASHBOARD_STATE_INITIAL, DashboardState, dashboard } from './reducers/dashboard';
import { PLOW_STATE_INITIAL, PlowState, plow } from './reducers/plow';
import { MODAL_STATE_INITIAL, ModalState, modal } from './reducers/modal';
import { USER_STATE_INITIAL, UserState, user } from './reducers/user';

import middlewareErrorCatcher from './store-middleware/error-catcher';

interface Window {
	__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
export interface RootState {
	content: ContentState;
	dashboard: DashboardState;
	modal: ModalState;
	plow: PlowState;
	routing: RoutingState;
	user: UserState;
}

// TODO: Define actions
// https://github.com/piotrwitek/typesafe-actions#createasyncaction
export type RootAction =
	| NavigateActions
	| ContentActions
	| DashboardActions
	| ModalActions
	| PlowActions
	| UserActions;

export type ThunkDispatchRoot = ThunkDispatch<RootState, void, RootAction>;
/**
 * @see https://github.com/reduxjs/redux-thunk/issues/103#issuecomment-395955111
 */
type ThunkStoreRoot = Store<RootState, RootAction> & { dispatch: ThunkDispatchRoot };

export type ThunkActionRoot<R = void> = ThunkAction<R, RootState, void, RootAction>;

export interface MiddlewareRoot {
	(api: MiddlewareAPI<ThunkDispatch<RootState, undefined, RootAction>, RootState>): (
		next: ThunkDispatchRoot,
	) => (action: RootAction) => RootAction;
}

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose: <Ext0, Ext1, StateExt0, StateExt1>(
	f1: StoreEnhancer<Ext0, StateExt0>,
	f2: StoreEnhancer<Ext1, StateExt1>,
) => StoreEnhancer<Ext0 & Ext1, StateExt0 & StateExt1> =
	(window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState: RootState = {
	routing: ROUTING_STATE_INITIAL,
	content: CONTENT_STATE_INITIAL,
	dashboard: DASHBOARD_STATE_INITIAL,
	modal: MODAL_STATE_INITIAL,
	plow: PLOW_STATE_INITIAL,
	user: USER_STATE_INITIAL,
};
// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://github.com/Polymer/pwa-starter-kit/wiki/4.-Redux-and-state-management
export const store = createStore(
	combineReducers({
		content,
		dashboard,
		modal,
		routing,
		plow,
		user,
	}),
	initialState,
	devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(thunk, middlewareErrorCatcher)),
) as ThunkStoreRoot;

export default store;
