import { AppPages } from '../constants';
import { SET_PAGE } from '../actions/routing';
import { RootAction } from '../store';

export type RoutingState = {
	page?: AppPages;
};

export const ROUTING_STATE_INITIAL = {};

export const routing = (
	state: RoutingState = ROUTING_STATE_INITIAL,
	action: RootAction,
): RoutingState => {
	if (action.type === SET_PAGE) {
		return {
			...state,
			page: action.page,
		};
	}

	return state;
};
