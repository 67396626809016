/* eslint-disable no-await-in-loop */
import { Action } from 'redux';

import sanitizedHtml from '@src/util/sanitize-html';
import { SearchParamKeys, updateURLWithNewParams } from './routing';
import {
	Content,
	CreateContentInput,
	UpdateContentInput,
	MutationDeleteMessageByIdArgs,
	MutationDeleteCampaignByIdArgs,
	MutationDeleteHelpPageByIdArgs,
} from '../../typings/schema.gen';
import { ThunkActionRoot } from '../store';
import { ActiveColumns } from '../reducers/content';
import { RequestStatus } from '../constants';

import fetchGql, { handleGraphQLErrors } from '../util/fetchGql';
import gql from '../util/gql';
import fragments from './query-fragments';

export const SET_ACTIVE_COLUMNS = 'SET_ACTIVE_COLUMNS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_FLOODGATES = 'SET_FLOODGATES';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_HELP_PAGES = 'SET_HELP_PAGES';

export const SET_GET_CONTENT_STATUS = 'SET_GET_CONTENT_STATUS';
export const SET_GET_ONE_CONTENT_STATUS = 'SET_GET_ONE_CONTENT_STATUS';

export const SET_MUTATE_CONTENT_STATUS = 'SET_MUTATE_CONTENT_STATUS';

export const SET_MUTATE_CONTENT = 'SET_MUTATE_CONTENT';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_FLOODGATE = 'DELETE_FLOODGATE';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_HELP_PAGE = 'DELETE_HELP_PAGE';

interface SetActiveColumns extends Action<typeof SET_ACTIVE_COLUMNS> {
	activeColumns: ActiveColumns;
}

interface SetMessages extends Action<typeof SET_MESSAGES> {
	messages: Content[];
}

interface SetFloodgates extends Action<typeof SET_FLOODGATES> {
	floodgates: Content[];
}

interface SetEditContent extends Action<typeof SET_MUTATE_CONTENT> {
	content?: Content;
}

interface SetCampaigns extends Action<typeof SET_CAMPAIGNS> {
	campaigns: Content[];
}

interface SetGetContentStatus extends Action<typeof SET_GET_CONTENT_STATUS> {
	status: RequestStatus;
}

interface SetGetOneContentStatus extends Action<typeof SET_GET_ONE_CONTENT_STATUS> {
	status: RequestStatus;
}

interface SetHelpPages extends Action<typeof SET_HELP_PAGES> {
	helpPages: Content[];
}

interface SetMutateContentStatus extends Action<typeof SET_MUTATE_CONTENT_STATUS> {
	status: RequestStatus;
}

interface DeleteMessage extends Action<typeof DELETE_MESSAGE> {
	id: string;
}

interface DeleteFloodgate extends Action<typeof DELETE_FLOODGATE> {
	id: string;
}

interface DeleteCampaign extends Action<typeof DELETE_CAMPAIGN> {
	id: string;
}

interface DeleteHelpPage extends Action<typeof DELETE_HELP_PAGE> {
	id: string;
}

export type ContentActions =
	| SetActiveColumns
	| SetMessages
	| SetFloodgates
	| SetCampaigns
	| SetHelpPages
	| SetGetContentStatus
	| SetGetOneContentStatus
	| SetMutateContentStatus
	| SetEditContent
	| DeleteMessage
	| DeleteFloodgate
	| DeleteHelpPage
	| DeleteCampaign;

export const setActiveColumns =
	(activeColumns: ActiveColumns): ThunkActionRoot =>
	(dispatch) => {
		dispatch(
			updateURLWithNewParams({
				[SearchParamKeys.ACTIVE_COLUMNS]: Object.keys(activeColumns).filter(
					(col) => activeColumns[col as keyof ActiveColumns],
				),
			}),
		);
		dispatch({ type: SET_ACTIVE_COLUMNS, activeColumns });
	};

const ALL_MESSAGES_QUERY = gql`
	query {
		messages {
			...Content
		}
	}
	${fragments.content}
`;

const ALL_FLOODGATES_QUERY = gql`
	query {
		floodgates {
			...Content
		}
	}
	${fragments.content}
`;

const ALL_CAMPAIGNS_QUERY = gql`
	query {
		campaigns {
			...Content
		}
	}
	${fragments.content}
`;

const ALL_HELP_PAGES_QUERY = gql`
	query {
		helpPages {
			...Content
		}
	}
	${fragments.content}
`;

const MESSAGE_BY_ID_QUERY = gql`
	query ($id: ID!) {
		messageById(id: $id) {
			...Content
		}
	}
	${fragments.content}
`;

const FLOODGATE_BY_ID_QUERY = gql`
	query ($id: ID!) {
		floodgateById(id: $id) {
			...Content
		}
	}
	${fragments.content}
`;

const CAMPAIGN_BY_ID_QUERY = gql`
	query ($id: ID!) {
		campaignById(id: $id) {
			...Content
		}
	}
	${fragments.content}
`;

const HELP_PAGE_BY_ID_QUERY = gql`
	query ($id: ID!) {
		helpPageById(id: $id) {
			...Content
		}
	}
	${fragments.content}
`;

export const fetchMessages = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_MESSAGES_QUERY,
		});
		handleGraphQLErrors(response.errors);
		const messages = response.data.messages?.filter((m) => m) || [];
		dispatch({ type: SET_MESSAGES, messages: messages as Content[] });
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchFloodgates = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_FLOODGATES_QUERY,
		});
		handleGraphQLErrors(response.errors);
		const floodgates = response.data.floodgates?.filter((m) => m) || [];
		dispatch({ type: SET_FLOODGATES, floodgates: floodgates as Content[] });
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchCampaigns = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_CAMPAIGNS_QUERY,
		});
		const campaigns = response.data.campaigns?.filter((m) => m) || [];

		handleGraphQLErrors(response.errors);
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		dispatch({ type: SET_CAMPAIGNS, campaigns: campaigns as Content[] });
	} catch (error) {
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchHelpPages = (): ThunkActionRoot => async (dispatch) => {
	dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.LOADING });
	try {
		const response = await fetchGql({
			query: ALL_HELP_PAGES_QUERY,
		});
		handleGraphQLErrors(response.errors);
		const helpPages = response.data.helpPages?.filter((m) => m) || [];
		dispatch({ type: SET_HELP_PAGES, helpPages: helpPages as Content[] });
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.SUCCESS });
	} catch (error) {
		dispatch({ type: SET_GET_CONTENT_STATUS, status: RequestStatus.ERROR });
		console.error(error);
	}
};

export const fetchMessageById =
	(id: string): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: MESSAGE_BY_ID_QUERY,
				variables: {
					id,
				},
			});
			const message = response.data.messageById;
			if (!message) {
				throw new Error('Requested message not found');
			}
			dispatch({ type: SET_MUTATE_CONTENT, content: message });
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const fetchFloodgateById =
	(id: string): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: FLOODGATE_BY_ID_QUERY,
				variables: {
					id,
				},
			});
			const floodgate = response.data.floodgateById;
			if (!floodgate) {
				throw new Error('Requested message not found');
			}
			dispatch({ type: SET_MUTATE_CONTENT, content: floodgate });
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const fetchCampaignById =
	(id: string): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: CAMPAIGN_BY_ID_QUERY,
				variables: {
					id,
				},
			});
			const campaign = response.data.campaignById;
			if (!campaign) {
				throw new Error('Requested campaign not found');
			}
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
			dispatch({ type: SET_MUTATE_CONTENT, content: campaign });
		} catch (error) {
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const fetchHelpPageById =
	(id: string): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: HELP_PAGE_BY_ID_QUERY,
				variables: {
					id,
				},
			});
			const helpPage = response.data.helpPageById;
			if (!helpPage) {
				throw new Error('Requested message not found');
			}
			dispatch({ type: SET_MUTATE_CONTENT, content: helpPage });
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_GET_ONE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const NEW_MESSAGE_MUTATION = gql`
	mutation ($input: CreateContentInput!) {
		createMessage(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const submitNewMessage =
	({
		title,
		content,
		draft,
		startTime,
		endTime,
		configJson,
	}: CreateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		// todo: if not a draft and blank startTime, make startTime current time;

		// if not draft and blank startTime, start time is now
		let finalStartTime = startTime;
		if (!draft && startTime === '') {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: NEW_MESSAGE_MUTATION,
				variables: {
					input: {
						title,
						content: sanitizedHtml(content),
						draft,
						startTime: finalStartTime,
						endTime,
						configJson,
					},
				},
			});

			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const NEW_FLOODGATE_MUTATION = gql`
	mutation ($input: CreateContentInput!) {
		createFloodgate(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const submitNewFloodgate =
	({
		title,
		content,
		draft,
		startTime,
		endTime,
		configJson,
		priority,
		otherContent,
	}: CreateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		let finalStartTime = startTime;
		if (!draft && !startTime) {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: NEW_FLOODGATE_MUTATION,
				variables: {
					input: {
						title,
						content: sanitizedHtml(content),
						draft,
						startTime: finalStartTime,
						endTime,
						configJson,
						priority,
						otherContent,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const NEW_CAMPAIGN_MUTATION = gql`
	mutation ($input: CreateContentInput!) {
		createCampaign(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const submitNewCampaign =
	({
		title,
		content,
		draft,
		startTime,
		endTime,
		url,
		configJson,
	}: CreateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		// if not draft and blank startTime, start time is now
		let finalStartTime = startTime;
		if (!draft && startTime === '') {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: NEW_CAMPAIGN_MUTATION,
				variables: {
					input: {
						title,
						content,
						draft,
						startTime: finalStartTime,
						endTime,
						url: url || null,
						configJson,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const DELETE_MESSAGE_QUERY = gql`
	mutation ($id: ID!) {
		deleteMessageById(id: $id)
	}
`;

const DELETE_FLOODGATE_QUERY = gql`
	mutation ($id: ID!) {
		deleteFloodgateById(id: $id)
	}
`;

const DELETE_CAMPAIGN_QUERY = gql`
	mutation ($id: ID!) {
		deleteCampaignById(id: $id)
	}
`;
const DELETE_HELP_PAGE_QUERY = gql`
	mutation ($id: ID!) {
		deleteHelpPageById(id: $id)
	}
`;

export const deleteMessage =
	({ id }: MutationDeleteMessageByIdArgs): ThunkActionRoot =>
	async (dispatch) => {
		if (!id) {
			throw new Error('Missing id for delete message');
		}
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DELETE_MESSAGE_QUERY,
				variables: {
					id,
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: DELETE_MESSAGE, id });
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const deleteFloodgate =
	({ id }: MutationDeleteMessageByIdArgs): ThunkActionRoot =>
	async (dispatch) => {
		if (!id) {
			throw new Error('Missing id for delete message');
		}
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DELETE_FLOODGATE_QUERY,
				variables: {
					id,
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: DELETE_FLOODGATE, id });
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const deleteCampaign =
	({ id }: MutationDeleteCampaignByIdArgs): ThunkActionRoot =>
	async (dispatch) => {
		if (!id) {
			throw new Error('Missing id for delete campaign');
		}
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DELETE_CAMPAIGN_QUERY,
				variables: {
					id,
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: DELETE_CAMPAIGN, id });
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const deleteHelpPage =
	({ id }: MutationDeleteHelpPageByIdArgs): ThunkActionRoot =>
	async (dispatch) => {
		if (!id) {
			throw new Error('Missing id for delete help page');
		}
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		try {
			const response = await fetchGql({
				query: DELETE_HELP_PAGE_QUERY,
				variables: {
					id,
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: DELETE_HELP_PAGE, id });
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const UPDATE_MESSAGE_MUTATION = gql`
	mutation ($input: UpdateContentInput!) {
		updateMessage(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const updateMessage =
	({
		id,
		title,
		content,
		draft,
		startTime,
		endTime,
		configJson,
	}: UpdateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });

		// if not draft and blank startTime, start time is now
		let finalStartTime = startTime;
		if (!draft && startTime === '') {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: UPDATE_MESSAGE_MUTATION,
				variables: {
					input: {
						id,
						title,
						content: sanitizedHtml(content),
						draft,
						startTime: finalStartTime,
						endTime,
						configJson,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const UPDATE_FLOODGATE_MUTATION = gql`
	mutation ($input: UpdateContentInput!) {
		updateFloodgate(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const updateFloodgate =
	({
		id,
		title,
		content,
		draft,
		startTime,
		endTime,
		configJson,
		priority,
		otherContent,
	}: UpdateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });

		// if not draft and blank startTime, start time is now
		let finalStartTime = startTime;
		if (!draft && !startTime) {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: UPDATE_FLOODGATE_MUTATION,
				variables: {
					input: {
						id,
						title,
						content: sanitizedHtml(content),
						draft,
						startTime: finalStartTime,
						endTime,
						configJson,
						priority,
						otherContent,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const UPDATE_CAMPAIGN_MUTATION = gql`
	mutation ($input: UpdateContentInput!) {
		updateCampaign(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const updateCampaign =
	({
		id,
		title,
		content,
		draft,
		startTime,
		endTime,
		url,
		configJson,
	}: UpdateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });
		// if not draft and blank startTime, start time is now
		let finalStartTime = startTime;
		if (!draft && startTime === '') {
			finalStartTime = new Date().toISOString();
		}
		try {
			const response = await fetchGql({
				query: UPDATE_CAMPAIGN_MUTATION,
				variables: {
					input: {
						id,
						title,
						content,
						draft,
						startTime: finalStartTime,
						endTime,
						url: url || null,
						configJson,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const NEW_HELP_PAGE_MUTATION = gql`
	mutation ($input: CreateContentInput!) {
		createHelpPage(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

export const submitNewHelpPage =
	({
		title,
		content,
		draft,
		startTime,
		endTime,
		configJson,
	}: CreateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });

		try {
			const existingHelpResponse = await fetchGql({
				query: ALL_HELP_PAGES_QUERY,
			});
			handleGraphQLErrors(existingHelpResponse.errors);
			const existingHelpPageCount = existingHelpResponse.data.helpPages?.length;
			if (existingHelpPageCount === undefined) {
				throw new Error('Unable to predict sequence number');
			}

			const response = await fetchGql({
				query: NEW_HELP_PAGE_MUTATION,
				variables: {
					input: {
						title,
						content: sanitizedHtml(content),
						draft,
						startTime,
						endTime,
						configJson,
						sequenceNumber: existingHelpPageCount + 1,
					},
				},
			});

			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

const UPDATE_HELP_PAGE_MUTATION = gql`
	mutation ($input: UpdateContentInput!) {
		updateHelpPage(input: $input) {
			...Content
		}
	}
	${fragments.content}
`;

// sequenceMap is helpPageIds in order of sequence [0,1,2,3,4, etc]
export const updateHelpPageSequences =
	(sequenceMap: string[]): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });

		try {
			// get all help pages
			const helpPagesResponse = await fetchGql({
				query: ALL_HELP_PAGES_QUERY,
			});
			handleGraphQLErrors(helpPagesResponse.errors);
			const helpPages = helpPagesResponse.data.helpPages?.flatMap((p) => (p ? [p] : [])) || [];

			// get out all help pages that are unlisted in the input so they can be sorted to (the end?)
			const unknownHelpPageIds = helpPages
				.filter((h) => !sequenceMap.includes(h.id))
				.map((h) => h.id);

			const helpPageById: Record<string, Content> = {};

			helpPages.forEach((h) => {
				helpPageById[h.id] = h;
			});

			sequenceMap.concat(unknownHelpPageIds);

			let i = 1;
			try {
				for (const id of sequenceMap) {
					const helpPage = helpPageById[id];
					await fetchGql({
						query: UPDATE_HELP_PAGE_MUTATION,
						variables: {
							input: {
								id,
								title: helpPage.title,
								content: sanitizedHtml(helpPage.content),
								draft: helpPage.draft,
								configJson: helpPage.configJson,
								sequenceNumber: i,
							},
						},
					});
					i += 1;
				}
			} catch (e) {
				console.error(e);
			}

			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};

export const updateHelpPage =
	({
		id,
		title,
		content,
		draft,
		configJson,
		sequenceNumber,
	}: UpdateContentInput): ThunkActionRoot =>
	async (dispatch) => {
		dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.LOADING });

		try {
			const response = await fetchGql({
				query: UPDATE_HELP_PAGE_MUTATION,
				variables: {
					input: {
						id,
						title,
						content: sanitizedHtml(content),
						draft,
						sequenceNumber,
						configJson,
					},
				},
			});
			handleGraphQLErrors(response.errors);
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.SUCCESS });
		} catch (error) {
			dispatch({ type: SET_MUTATE_CONTENT_STATUS, status: RequestStatus.ERROR });
			console.error(error);
		}
	};
