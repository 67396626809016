export enum Breakpoint {
	PHONE_XL = '576px',
	TABLET = '768px',
	DESKTOP = '1080px',
	DESKTOP_XL = '1400px',
	DESKTOP_ULTRAWIDE = '2000px',
}

export const MILLIS_PER_MINUTE = 60 * 1000;
export const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
export const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;

export const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * 7;
export const MINUTES_PER_MONTH = MINUTES_PER_DAY * 30;

export enum AppPages {
	MESSAGES = 'messages',
	NEW_MESSAGE = 'messages/new',
	EDIT_MESSAGE = 'messages/edit',
	//
	FLOODGATES = 'floodgates',
	NEW_FLOODGATE = 'floodgates/new',
	EDIT_FLOODGATE = 'floodgates/edit',
	//
	CAMPAIGNS = 'campaigns',
	NEW_CAMPAIGN = 'campaigns/new',
	EDIT_CAMPAIGN = 'campaigns/edit',
	//
	HELP_PAGES = 'help-pages',
	NEW_HELP_PAGE = 'help-pages/new',
	EDIT_HELP_PAGE = 'help-pages/edit',
	//
	DASHBOARDS = 'dashboards',
	NEW_DASHBOARD = 'dashboards/new',
	EDIT_DASHBOARD = 'dashboards/edit',
	//
	PLOWS = 'plows',
	//
	DEACTIVATE_LAYERS = 'deactivate-layers',
}

export enum RequestStatus {
	UNSUBMITTED = 'unsubmitted',
	LOADING = 'loading',
	ERROR = 'error',
	SUCCESS = 'success',
}

export enum DashboardStatus {
	PENDING = 'pending',
	LIVE = 'live',
}

export enum PlowStatus {
	VISIBLE = 'visible',
	HIDDEN = 'hidden',
	ERROR = 'error',
}
