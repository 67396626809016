import { createSelector } from 'reselect';
import { RootAction, RootState } from '../store';

import { User } from '../../typings/schema.gen';
import { RequestStatus } from '../constants';

import { SET_LOGIN_STATUS, SET_USER } from '../actions/user';

export type UserState = {
	loginStatus: RequestStatus;
	user?: User;
};

export const USER_STATE_INITIAL: UserState = {
	loginStatus: RequestStatus.UNSUBMITTED,
};

export const user = (state: UserState = USER_STATE_INITIAL, action: RootAction): UserState => {
	if (action.type === SET_LOGIN_STATUS) {
		return {
			...state,
			loginStatus: action.status,
		};
	}
	if (action.type === SET_USER) {
		return {
			...state,
			user: action.user,
		};
	}
	return state;
};

export const isLoggedIn = createSelector(
	(state: RootState) => state.user.user,
	(user): boolean => !!user,
);
