import gql from '../util/gql';

export const fragments = {
	content: gql`
		fragment Content on Content {
			id
			title
			content
			status
			draft
			startTime
			endTime
			createdTime
			createdBy
			lastModifiedBy
			lastModifiedTime
			url
			configJson
			sequenceNumber
			priority
			otherContent
		}
	`,
	dashboard: gql`
		fragment Dashboard on Dashboard {
			id
			title
			icon
			createdBy
			createdTime
			lastModifiedBy
			lastModifiedTime
			components {
				id
				dashboardId
				type
				title
				priorityThreshold
				sequenceNumber
				createdBy
				createdTime
				lastModifiedBy
				lastModifiedTime
				contents {
					id
					componentId
					type
					sequenceNumber
					configJson
					createdBy
					createdTime
					lastModifiedBy
					lastModifiedTime
				}
			}
		}
	`,
};

export default fragments;
