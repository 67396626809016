import { RootAction } from '../store';

export enum ModalView {
	SETTINGS = 'settings',
	LOGIN = 'login',
	FORGOT_PASSWORD = 'forgot-password',
	DELETE_MESSAGE = 'delete-message',
	DELETE_FLOODGATE = 'delete-floodgate',
	DELETE_CAMPAIGN = 'delete-campaign',
	DELETE_HELP_PAGE = 'delete-help-page',
	DELETE_DASHBOARD = 'delete-dashboard',
	EDIT_GALLERY = 'edit-gallery',
	EDIT_LIST = 'edit-list',
}

export const modalIsClosable: Record<ModalView, boolean> = {
	[ModalView.SETTINGS]: true,
	[ModalView.LOGIN]: false,
	[ModalView.FORGOT_PASSWORD]: false,
	[ModalView.DELETE_MESSAGE]: true,
	[ModalView.DELETE_FLOODGATE]: true,
	[ModalView.DELETE_CAMPAIGN]: true,
	[ModalView.DELETE_DASHBOARD]: true,
	[ModalView.DELETE_HELP_PAGE]: true,
	[ModalView.EDIT_GALLERY]: true,
	[ModalView.EDIT_LIST]: true,
};

export interface ModalState {
	modalView?: ModalView | null;
}

export const MODAL_STATE_INITIAL: ModalState = {
	modalView: null,
};

export const NAVIGATE_MODAL = 'NAVIGATE_MODAL';

export const modal = (state: ModalState = MODAL_STATE_INITIAL, action: RootAction): ModalState => {
	switch (action.type) {
		case NAVIGATE_MODAL:
			return {
				...state,
				modalView: action.modalView,
			};
		default:
			return state;
	}
};
