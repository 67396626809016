import sanitizeHtml from 'sanitize-html';

const sanitizedHtml = (html: string): string => {
	const cleanHTML = sanitizeHtml(html, {
		allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
		allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
		allowedAttributes: {
			...sanitizeHtml.defaults.allowedAttributes,
			h1: ['id'],
			h2: ['id'],
			h3: ['id'],
			h4: ['id'],
			h5: ['id'],
			h6: ['id'],
		},
	});
	return cleanHTML;
};

export default sanitizedHtml;
