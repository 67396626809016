/* eslint-disable dot-notation */
import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html, unsafeCSS } from 'lit';
import { connect } from 'pwa-helpers';
import { classMap } from 'lit/directives/class-map';

import { AppPages, Breakpoint } from '../../constants';

import { store, RootState } from '../../store';
import { User } from '../../../typings/schema.gen';
import { logout } from '../../actions/user';

import StylesContent from '../../styles/styles-content';
import StylesDataTable from '../../styles/styles-data-table';
import StylesButton from '../../styles/styles-button';

import { PopperPosition } from '../dropdown/popper-container';
import '../dropdown/dropdown-menu';
import './navigation-account';

const newLinkMap = {
	[AppPages.MESSAGES]: { href: AppPages.NEW_MESSAGE, text: 'New Message' },
	[AppPages.NEW_MESSAGE]: { href: AppPages.NEW_MESSAGE, text: 'New Message' },
	[AppPages.EDIT_MESSAGE]: { href: AppPages.NEW_MESSAGE, text: 'New Message' },
	//
	[AppPages.FLOODGATES]: { href: AppPages.NEW_FLOODGATE, text: 'New Floodgate' },
	[AppPages.NEW_FLOODGATE]: { href: AppPages.NEW_FLOODGATE, text: 'New Floodgate' },
	[AppPages.EDIT_FLOODGATE]: { href: AppPages.NEW_FLOODGATE, text: 'New Floodgate' },
	//
	[AppPages.CAMPAIGNS]: { href: AppPages.NEW_CAMPAIGN, text: 'New Campaign' },
	[AppPages.NEW_CAMPAIGN]: { href: AppPages.NEW_CAMPAIGN, text: 'New Campaign' },
	[AppPages.EDIT_CAMPAIGN]: { href: AppPages.NEW_CAMPAIGN, text: 'New Campaign' },
	//
	[AppPages.HELP_PAGES]: { href: AppPages.NEW_HELP_PAGE, text: 'New Help Page' },
	[AppPages.NEW_HELP_PAGE]: { href: AppPages.NEW_HELP_PAGE, text: 'New Help Page' },
	[AppPages.EDIT_HELP_PAGE]: { href: AppPages.NEW_HELP_PAGE, text: 'New Help Page' },
	//
	[AppPages.DASHBOARDS]: { href: AppPages.NEW_DASHBOARD, text: 'New Dashboard' },
	[AppPages.NEW_DASHBOARD]: { href: AppPages.NEW_DASHBOARD, text: 'New Dashboard' },
	[AppPages.EDIT_DASHBOARD]: { href: AppPages.NEW_DASHBOARD, text: 'New Dashboard' },
	//
	[AppPages.DEACTIVATE_LAYERS]: { href: AppPages.DEACTIVATE_LAYERS, text: 'Deactivate Layers' },
	//
	[AppPages.PLOWS]: undefined,
};

@customElement('navigation-bar')
export class NavigationBar extends connect(store)(LitElement) {
	@property({ type: String }) protected page?: AppPages;

	@property({ type: Boolean }) protected menuOpen = false;

	@property({ type: Object }) protected user?: User;

	public static get styles(): CSSResultArray {
		const tabletBreakpoint = unsafeCSS(Breakpoint.TABLET);
		return [
			StylesContent,
			StylesDataTable,
			StylesButton,
			css`
				:host {
					z-index: 2;
				}
				nav {
					height: 100%;
					width: 100%;
					box-shadow: var(--box-shadow);
				}

				ul {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: row;
					list-style: none;
					padding: 0 var(--gap);
					margin: 0;
				}

				a {
					width: 4rem;
					padding: 0.25rem;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					cursor: pointer;
				}

				.nav-link_title {
					font-size: 0.7rem;
					color: rgb(var(--steel));
					text-align: center;
				}

				.nav-link[selected] > .nav-link_title {
					color: rgb(var(--off-black));
				}

				.nav-link_icon {
					width: 3rem;
					height: 3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
				}

				.nav-link[selected] > .nav-link_icon {
					background-color: rgb(var(--light-purple));
				}

				.btn-menu-expand-expanded {
					box-shadow: none;
				}

				.btn-menu-expand-expanded .more-arrow {
					transform: rotate(0deg);
					fill: #000;
				}

				.btn-menu-expand-expanded > .nav-link_icon {
					background-color: rgb(var(--light-gray));
				}

				.account-link {
					margin-left: var(--gap);
					margin-bottom: auto;
					margin-top: auto;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.divider {
					height: 100%;
					border-left: 1px solid rgb(var(--off-black));
					margin: 0 var(--gap);
				}

				.btn-logout {
					text-align: left;
					background-color: rgb(var(--off-black));
				}

				.btn-menu-expand {
					flex-direction: column;
					padding: 0.25rem;
					margin-left: 0.25rem;
				}

				.more-item {
					background-color: rgb(var(--off-black));
					color: rgb(var(--light-gray));
					width: 15rem;
					height: 2.5rem;
					border-top: 1px solid rgb(var(--dark-gray));
				}

				.more-item > a {
					width: 100%;
					height: 100%;
					padding: 0;
					justify-content: flex-start;
					flex-direction: row;
					align-items: center;
				}

				.more-item a:hover {
					color: rgb(var(--light-gray));
				}

				.more-item:hover {
					background-color: rgb(var(--dark-gray));
				}

				.arrow {
					margin-right: calc(var(--gap));
					transform: rotate(0deg);
				}

				.more-arrow {
					padding-right: calc(var(--gap) / 3);
					margin-left: calc(var(--gap) / 4);
					margin-right: 0;
					transform: rotate(-90deg);
					transform-origin: 0.3rem 0.3rem;
				}

				.nav-link_title-more {
					display: flex;
					align-items: inherit;
				}

				.arrow-light {
					stroke: white;
					margin-left: calc(var(--gap));
					height: 1.2rem;
					width: 1.2rem;
				}

				.side-nav-only {
					display: none;
				}

				@media all and (min-width: ${tabletBreakpoint}) {
					ul {
						flex-direction: column;
						padding: var(--gap) 0;
					}

					.account-link {
						margin-left: 0;
						margin-top: auto;
						margin-bottom: var(--gap-half);
					}

					.divider {
						height: 0;
						width: 100%;
						border-top: 1px solid rgb(var(--off-black));
						margin: 0;
						margin-top: calc(var(--gap) / 3);
					}

					a {
						width: 4rem;
					}

					#more-menu {
						display: none;
					}

					.side-nav-only {
						display: block;
					}
				}
			`,
		];
	}

	protected logout(): void {
		this.menuOpen = false;
		store.dispatch(logout());
	}

	protected hideMenu(): void {
		this.menuOpen = false;
	}

	protected openMenu(): void {
		this.menuOpen = true;
	}

	protected newContentLink(): TemplateResult {
		if (!this.page || this.page === AppPages.DEACTIVATE_LAYERS) {
			return html``;
		}
		const newLink = newLinkMap[this.page];
		if (!newLink) {
			return html``;
		}
		const { href, text } = newLink;
		return html`<a href="/${href}" class="nav-link">
			<span class="nav-link_icon">
				<img src="${process.env.ICONS.add}" />
			</span>
			<span class="nav-link_title">${text}</span>
		</a>`;
	}

	protected renderMoreMenu(): TemplateResult {
		if (
			!process.env.DASHBOARDS_ENABLED &&
			!process.env.PLOWS_ENABLED &&
			!process.env.HELP_PAGES_ENABLED
		) {
			return html``;
		}
		return html`<li id="more-menu">
			<dropdown-menu
				position="${PopperPosition.BOTTOM}"
				@show=${this.openMenu}
				@hide=${this.hideMenu}
			>
				<button slot="toggler" 
					class=${classMap({
						'btn': true,
						'nav-link': true,
						'btn-menu-expand': true,
						'btn-menu-expand-expanded': this.menuOpen,
					})}
				id="more-links">
					<span class="nav-link_icon">
						<img src="${process.env.ICONS['filter-full']}" />
					</span>
					<span class="nav-link_title nav-link_title-more">More<svg
							class="arrow more-arrow"
						>
							<use href="${process.env.ICONS['expand-arrow']}"></use>
						</svg></span>
					
				</button>
				${
					process.env.DASHBOARDS_ENABLED
						? html`<div class="more-item">
								<a href="/${AppPages.DASHBOARDS}">
									<svg class="arrow arrow-light">
										<use href="${process.env.ICONS.dashboards}"></use>
									</svg>
									Dashboards
								</a>
						  </div>`
						: html``
				}
					${
						process.env.PLOWS_ENABLED
							? html`<div class="more-item">
									<a href="/${AppPages.PLOWS}">
										<svg class="arrow arrow-light">
											<use href="${process.env.ICONS.plows}"></use>
										</svg>
										AVL Controls
									</a>
							  </div>`
							: html``
					}
					${
						process.env.HELP_PAGES_ENABLED
							? html`<div class="more-item">
									<a href="/${AppPages.HELP_PAGES}">
										<svg class="arrow arrow-light">
											<use href="${process.env.ICONS.help}"></use>
										</svg>
										Help Pages
									</a>
							  </div>`
							: html``
					}
				</div>
			</dropdown-menu>
		</li>`;
	}

	public render(): TemplateResult {
		return html`
			<nav>
				<ul>
					${process.env.MESSAGES_ENABLED
						? html`<li>
								<a
									href="/${AppPages.MESSAGES}"
									class="nav-link"
									?selected=${this.page === AppPages.MESSAGES}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['messages-full']}" />
									</span>
									<span class="nav-link_title">Messages</span>
								</a>
						  </li>`
						: html``}
					${process.env.FLOODGATES_ENABLED
						? html`<li>
								<a
									href="/${AppPages.FLOODGATES}"
									class="nav-link"
									?selected=${this.page === AppPages.FLOODGATES}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS.floodgate}" width="90%" />
									</span>
									<span class="nav-link_title">Floodgates</span>
								</a>
						  </li>`
						: html``}
					${process.env.CAMPAIGNS_ENABLED
						? html`<li>
								<a
									href="/${AppPages.CAMPAIGNS}"
									class="nav-link"
									?selected=${this.page === AppPages.CAMPAIGNS}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['campaigns-full']}" />
									</span>
									<span class="nav-link_title">Campaigns</span>
								</a>
						  </li>`
						: html``}
					${process.env.DASHBOARDS_ENABLED
						? html`<li class="side-nav-only">
								<a
									href="/${AppPages.DASHBOARDS}"
									class="nav-link"
									?selected=${this.page === AppPages.DASHBOARDS}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['dashboards-full']}" />
									</span>
									<span class="nav-link_title">Dashboards</span>
								</a>
						  </li>`
						: html``}
					${process.env.PLOWS_ENABLED
						? html`<li class="side-nav-only">
								<a
									href="/${AppPages.PLOWS}"
									class="nav-link"
									?selected=${this.page === AppPages.PLOWS}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['plows-full']}" />
									</span>
									<span class="nav-link_title">Plows</span>
								</a>
						  </li>`
						: html``}
					${process.env.HELP_PAGES_ENABLED
						? html`<li class="side-nav-only">
								<a
									href="/${AppPages.HELP_PAGES}"
									class="nav-link"
									?selected=${this.page === AppPages.HELP_PAGES}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['help-full']}" />
									</span>
									<span class="nav-link_title">Help Pages</span>
								</a>
						  </li>`
						: html``}
					${process.env.DEACTIVATE_LAYERS_ENABLED
						? html`<li class="side-nav-only">
								<a
									href="/${AppPages.DEACTIVATE_LAYERS}"
									class="nav-link"
									?selected=${this.page === AppPages.DEACTIVATE_LAYERS}
								>
									<span class="nav-link_icon">
										<img src="${process.env.ICONS['layer']}" />
									</span>
									<span class="nav-link_title">Deactivate Layers</span>
								</a>
						  </li>`
						: html``}
					${this.renderMoreMenu()}

					<li>
						<div class="divider"></div>
					</li>
					<li>${this.newContentLink()}</li>
					<!-- spacing -->

					<li class="account-link">
						<dropdown-menu
							position="${PopperPosition.BOTTOM}"
							@show=${this.openMenu}
							@hide=${this.hideMenu}
						>
							<navigation-account
								slot="toggler"
								initials=${this.nameToInitials()}
							></navigation-account>
							<div class="column-select-item">
								<button class="btn btn-dark btn-logout" @click=${this.logout}>Logout</button>
							</div>
						</dropdown-menu>
					</li>
				</ul>
			</nav>
		`;
	}

	private nameToInitials(): string {
		if (!this.user?.fullname) {
			return '??';
		}
		const namesArray = this.user.fullname.trim().split(' ');
		if (namesArray.length === 1) {
			return `${namesArray[0].charAt(0)}`;
		}
		return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
	}

	public stateChanged(state: RootState): void {
		this.page = state.routing.page;
		this.user = state.user.user;
	}
}

export default NavigationBar;
