import { customElement, property } from 'lit/decorators.js';
import { CSSResultArray, css, TemplateResult, LitElement, html } from 'lit';
import { connect } from 'pwa-helpers';

import { RootState, store } from '../../store';
import StylesContent from '../../styles/styles-content';
import findOriginalTarget from '../../util/find-original-target';
import { navigateModal } from '../../actions/routing-modal';
import { MODAL_STATE_INITIAL, ModalView, modalIsClosable } from '../../reducers/modal';

@customElement('modal-container')
export default class ModalContainer extends connect(store)(LitElement) {
	@property({ type: Boolean, reflect: true }) public open?: boolean;

	@property({ type: String }) private modalView?: ModalView | null = MODAL_STATE_INITIAL.modalView;

	public static get styles(): CSSResultArray {
		const styles = [
			StylesContent,
			// language=CSS
			css`
				@media screen and (prefers-reduced-motion: reduce) {
					:host {
						transition: none;
					}
				}

				:host {
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(var(--dialog-modals-bg, 0, 0, 0), 0.5);
					backdrop-filter: var(--filter-effect-modal);
					transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
					opacity: 0;
					visibility: hidden;
					z-index: 10;
				}

				:host([open]) {
					opacity: 1;
					visibility: visible !important;
				}

				.modal-container {
					display: none;
					width: 100%;
					height: 100%;
					align-items: center;
					justify-content: center;
					pointer-events: none;
				}

				@media screen and (prefers-reduced-motion: reduce) {
					:host([open]) .modal-container {
						animation: none;
					}
				}
				:host([open]) .modal-container {
					animation: enlarge 0.25s;
					display: flex;
				}

				@keyframes enlarge {
					from {
						transform: perspective(75vmin) translateZ(-25vmin);
						opacity: 0.5;
					}
					to {
						transform: perspective(0);
						opacity: 1;
					}
				}
			`,
		];
		return styles;
	}

	public render(): TemplateResult {
		return html`
			<div role="document" class="modal-container">
				${this.modalView === ModalView.LOGIN ? html`<login-modal></login-modal>` : undefined}
				${this.modalView === ModalView.FORGOT_PASSWORD
					? html`<forgot-password-modal></forgot-password-modal>`
					: undefined}
				${this.modalView === ModalView.DELETE_MESSAGE
					? html`<delete-message-modal></delete-message-modal>`
					: undefined}
				${this.modalView === ModalView.DELETE_FLOODGATE
					? html`<delete-floodgate-modal></delete-floodgate-modal>`
					: undefined}
				${this.modalView === ModalView.DELETE_CAMPAIGN
					? html`<delete-campaign-modal></delete-campaign-modal>`
					: undefined}
				${this.modalView === ModalView.DELETE_HELP_PAGE
					? html`<delete-help-page-modal></delete-help-page-modal>`
					: undefined}
				${this.modalView === ModalView.DELETE_DASHBOARD
					? html`<delete-dashboard-modal></delete-dashboard-modal>`
					: undefined}
				${this.modalView === ModalView.EDIT_LIST
					? html`<event-list-modal></event-list-modal>`
					: undefined}
				${this.modalView === ModalView.EDIT_GALLERY
					? html`<gallery-modal></gallery-modal>`
					: undefined}
			</div>
		`;
	}

	public constructor() {
		super();
		this.addEventListener('click', (e: MouseEvent): void => {
			if (
				e.currentTarget === findOriginalTarget(e) &&
				this.modalView &&
				modalIsClosable[this.modalView]
			) {
				void store.dispatch(navigateModal(null));
			}
		});

		document.addEventListener('keydown', (e: KeyboardEvent): void => {
			if (this.open && e.code === 'Escape' && this.modalView && modalIsClosable[this.modalView]) {
				void store.dispatch(navigateModal(null));
			}
		});
	}

	public stateChanged(state: RootState): void {
		this.modalView = state.modal.modalView;
	}

	public willUpdate(): void {
		this.open = !!this.modalView;
	}
}
